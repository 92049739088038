import Responsive from '@/utils/Responsive';

import { DataGridSectionMobileOld } from './DataGridSectionMobileOld';
import type { IDataGridSectionOld } from './DataGridSectionOld';
import { DataGridSectionOld } from './DataGridSectionOld';

const DataGridSectionWrapperOld = (props: IDataGridSectionOld) => (
    <Responsive
        desktop={<DataGridSectionOld {...props} />}
        mobile={<DataGridSectionMobileOld {...props} />}
    />
);
export default DataGridSectionWrapperOld;
