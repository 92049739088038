import type { StripeElementsOptions } from '@stripe/stripe-js';

export const StripeOptionsSupreme: StripeElementsOptions = {
    fonts: [
        {
            family: 'Gerstner Programm FSL',
            src: 'url(https://app.bpmsupreme.com/_next/static/media/GerstnerProgrammFSLWeb-Bold.fd81b57e.woff2)',
            weight: '700',
        },
        {
            family: 'Gerstner Programm FSL',
            src: 'url(https://app.bpmsupreme.com/_next/static/media/GerstnerProgrammFSLWeb-Medium.1cf69ddc.woff2)',
            weight: '500',
        },
        {
            family: 'Gerstner Programm FSL',
            src: 'url(https://app.bpmsupreme.com/_next/static/media/GerstnerProgrammFSLWeb-Regular.1cf69ddc.woff2)',
            weight: '400',
        },
        {
            family: 'Gerstner Programm FSL',
            src: 'url(https://app.bpmsupreme.com/_next/static/media/GerstnerProgrammFSLWeb-Light.597ff2a0.woff2)',
            weight: '300',
        },
    ],
    appearance: {
        theme: 'flat',
        variables: {
            colorPrimary: '#F73E00',
            colorBackground: '#212121',
            colorText: '#B3B3B3',
            borderRadius: '4px',
        }
    },
};
