import className from 'classnames';
import type { ReactElement, ReactNode, Ref } from 'react';

import SeoableText from '@/components/SeoableText';

type ISectionProps = {
    background?: string;
    padding?: string;
    classNames?: string;
    style?: Object;
    title?: string;
    smallTitle?: string | null;
    titleClass?: string;
    fullWidth?: boolean;
    children: ReactNode;
    ref?: Ref<any>;
    customTitle?: ReactElement;
};

const Section = (props: ISectionProps) => {
    const fullWidth = className(
        'mx-auto',
        'w-screen',
        'max-w-full',
        `${props.background && props.background}`,
        `${props.padding && props.padding}`,
        `${props.classNames && props.classNames}`
    );

    const normal = className(
        'mx-auto',
        'max-w-7xl',
        `${props.padding ? props.padding : 'px-16 py-16'}`,
        `${props.background && props.background}`,
        `${props.classNames && props.classNames}`
    );

    return (
        <div
            ref={props.ref ? props.ref : null}
            className={props.fullWidth ? fullWidth : normal}
            style={props.style ? props.style : {}}
        >
            {props.title && !props.customTitle ? (
                <SeoableText element="h3"
                    className={className(
                        `${props.smallTitle ? 'md:pb-[2rem] pb-[5rem] ' : 'md:pb-[9.2rem] pb-[9.3rem] '} md:text-[4.8rem] tracking-[-0.007rem] md:tracking-[-0.09rem] block  text-center text-3xl-m leading-sectionTitleMobile md:leading-[4.8rem] `,
                        props.titleClass && props.titleClass
                    )}
                    dangerouslySetInnerHTML={{ __html: props.title }}
                />
            ) : props.customTitle}
            {props.smallTitle && <SeoableText element="h4" className='mx-auto max-w-[67rem] pb-[4rem] text-center text-[1.8rem] leading-[2.3rem] text-bpm-white opacity-50 md:text-[2.2rem] md:leading-[2.8rem]' dangerouslySetInnerHTML={{ __html: props.smallTitle }} />}
            {props.children}
        </div>
    );
};

export { Section };
