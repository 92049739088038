import { motion } from 'framer-motion';
import { observer } from 'mobx-react';

import { Button } from '@/components/Button';
import NextImage from '@/components/image';
import SeoableText from '@/components/SeoableText';
import type { ComponentSectionsGetTheAppWithIcon, GlobalEntity } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';

type IGetTheAppWithIconProps = {
    data: ComponentSectionsGetTheAppWithIcon;
    global: GlobalEntity;
};

const GetTheAppWithIcon = observer((props: IGetTheAppWithIconProps) => (
    <div className={'md:m-16'}>
        <Section
            padding={
                'mt-36 mx-8 md:mx-auto px-8 pt-[3.5rem] pb-8 md:pb-16 md:px-16 md:py-16 md:w-full'
            }
        >
            <div
                className={
                    'flex w-full flex-col items-center justify-center text-white'
                }
            >
                <motion.div
                    transition={{ duration: 0.5 }}
                    whileInView={{
                        y: 0,
                        opacity: 1,
                    }}
                    style={{
                        // y: 200,
                        opacity: 0,
                    }}
                    className={'relative mt-32 mb-[4rem] w-[10rem]'}>
                    <NextImage
                        src={''}
                        priority
                        media={props.data.Icon}
                        alt={props.data.Title}
                        width={100}
                        height={100}
                        layout="responsive"
                        objectFit="cover"
                    />
                </motion.div>
                <motion.div
                    transition={{ duration: 0.5 }}
                    whileInView={{
                        y: 0,
                        opacity: 1,
                    }}
                    style={{
                        // y: 200,
                        opacity: 0,
                    }} >
                    <SeoableText element="h3" className={'text-center text-[4rem] leading-[4rem] md:text-[5.6rem] md:leading-[5.6rem]'} dangerouslySetInnerHTML={{ __html: props.data.Title }} />
                </motion.div>

                <motion.div
                    transition={{ duration: 0.5 }}
                    whileInView={{
                        y: 0,
                        opacity: 1,
                    }}
                    style={{
                        // y: 200,
                        opacity: 0,
                    }} className={'mt-[5rem] flex flex-row '}>
                    <Button onClick={() => window.open(props.global.attributes?.iOSStoreLink ?? '', 'blank')} className='mr-[1rem] !px-[2rem] !py-[0.9rem] !text-[2rem] !leading-[3rem]'>App Store</Button>
                    <Button onClick={() => window.open(props.global.attributes?.GooglePlayStoreLink ?? '', 'blank')} className='!px-[2rem] !py-[0.9rem] !text-[2rem] !leading-[3rem]'>Google Play</Button>
                </motion.div>

            </div>
        </Section>
    </div>
));

export { GetTheAppWithIcon };
