import classNames from 'classnames';
import type { ReactNode } from 'react';
import {
    useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { toast } from 'react-toastify';

import styles from './SocialShare.module.scss';

function objectToGetParams(object: { [key: string]: string | number | undefined | null }) {
    const params = Object.entries(object)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

    return params.length > 0 ? `?${params.join('&')}` : '';
}

export interface SocialShareProps {
    platforms: Array<'facebook' | 'twitter' | 'linkedin' | 'link' | 'email'>;
    url: string;
    icon: ReactNode;
    title?: string;
    platformColor?: string
}

export function SocialShare({
    platforms, url, icon, title, platformColor
}: SocialShareProps) {
    const [isOpen, setIsOpen] = useState(false);
    const wrapper = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleDocClick = (event: MouseEvent) => {
            if (!wrapper?.current) return;
            if (wrapper.current !== event.target && !wrapper.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleDocClick);
        return () => {
            document.removeEventListener('click', handleDocClick);
        };
    }, []);

    const openShareWindow = useCallback((windowUrl: string) => {
        const left = (window.screen.width - 570) / 2;
        const top = (window.screen.height - 570) / 2;
        const params = `menubar=no,toolbar=no,status=no,width=570,height=570,top=${top},left=${left}`;
        window.open(windowUrl, 'Share', params);
    }, []);

    const copyUrl = useCallback(() => {
        navigator.clipboard?.writeText(url);
        toast('Link copied to clipboard');
    }, [url]);

    const platformsData = useMemo(
        () => [
            platforms.includes('facebook') && {
                id: 'facebook',
                label: 'Facebook',
                icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.581 18v-5.93h1.838l.31-1.965H12.7V9.12c0-.513-.07-1.159.667-1.159h1.47V6h-2.085c-1.756 0-2.547 1.313-2.547 2.915v1.189H9v1.965h1.205V18h2.376Z" fill="#919191" /></svg>,
                onClick: () => {
                    openShareWindow(`https://www.facebook.com/sharer.php?u=${url}`);
                    setIsOpen(false);
                },
            },
            platforms.includes('twitter') && {
                id: 'twitter',
                label: 'Twitter',
                icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.88 9.84c0-.11 0-.218-.007-.325a4.934 4.934 0 0 0 1.195-1.28 4.604 4.604 0 0 1-1.378.376 2.46 2.46 0 0 0 1.053-1.378 4.606 4.606 0 0 1-1.52.59A2.381 2.381 0 0 0 14.477 7a2.418 2.418 0 0 0-2.394 2.495c0 .195.02.388.063.578a6.74 6.74 0 0 1-4.936-2.727 2.686 2.686 0 0 0 .741 3.42 2.302 2.302 0 0 1-1.083-.332v.032a2.544 2.544 0 0 0 1.92 2.52 2.24 2.24 0 0 1-1.079.036 2.434 2.434 0 0 0 2.236 1.782A4.626 4.626 0 0 1 6.4 15.849 6.463 6.463 0 0 0 10.07 17c4.403-.001 6.81-3.831 6.81-7.16Z" fill="#919191" /></svg>,
                onClick: () => {
                    openShareWindow(`https://twitter.com/intent/tweet?url=${url}`);
                    setIsOpen(false);
                },
            },
            platforms.includes('linkedin') && {
                id: 'linkedin',
                label: 'LinkedIn',
                icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.8 16.991h1.874V9.499H6.8v7.492Zm7.493-7.492a3.435 3.435 0 0 0-2.497 1.249v-1.25H9.923v7.493h1.873v-4.372a1.591 1.591 0 0 1 1.872-1.247c.794.05 1.222 1.105 1.25 1.247v4.372l1.865.009v-5.19a2.338 2.338 0 0 0-2.49-2.311ZM7.737 7a.936.936 0 1 0 0 1.875.936.936 0 0 0 0-1.876v.001Z" fill="#919191" /></svg>,
                onClick: () => {
                    openShareWindow(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`);
                    setIsOpen(false);
                },
            },
            platforms.includes('link') && {
                id: 'link',
                label: 'Copy Link',
                icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m12.537 11.64-.5-.51c-.45-.45-1.25-.45-1.7 0l-2.98 2.99c-.47.47-.47 1.24 0 1.71l.63.63c.45.46 1.25.46 1.7 0l1.07-1.07c.57.49 1.29.77 2.05.78l-1.7 1.7c-.6.61-1.41.94-2.27.94-.86 0-1.66-.33-2.27-.94l-.63-.63a3.205 3.205 0 0 1 0-4.53l2.98-2.99c1.21-1.21 3.32-1.21 4.53 0l.86.86-1.06 1.06s-.33.33-.71 0Z" fill="#919191" /><path d="m11.527 12.17.5.51c.45.45 1.25.45 1.7 0l2.98-2.99c.47-.47.47-1.24 0-1.71l-.63-.63c-.45-.46-1.25-.46-1.7 0l-1.07 1.07c-.57-.49-1.29-.77-2.05-.78l1.7-1.7c.6-.61 1.41-.94 2.27-.94.86 0 1.66.33 2.27.94l.63.63a3.205 3.205 0 0 1 0 4.53l-2.98 2.99c-1.21 1.21-3.32 1.21-4.53 0l-.86-.86 1.06-1.06s.33-.33.71 0Z" fill="#919191" /></svg>,
                onClick: () => {
                    copyUrl();
                    setIsOpen(false);
                },
            },
            platforms.includes('email') && {
                id: 'email',
                label: 'Email',
                icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.33 7H5.67L12 11.75 18.33 7Z" fill="#919191" /><path d="M12 14.25 5 9v8h14V9l-7 5.25Z" fill="#919191" /></svg>,
                onClick: () => {
                    const windowUrl = `mailto:your-recipient@bpmsupreme.com${objectToGetParams({ subject: 'Giveaway', body: url })}`;
                    window.open(windowUrl, 'Share');
                    setIsOpen(false);
                },
            },
        ].filter(Boolean),
        [copyUrl, openShareWindow, platforms, url]
    );

    return (
        <div
            className={classNames(styles['social-share'], {
                [styles['social-share--open'] as any]: isOpen,
            })}
            ref={wrapper}
        >
            <button type="button" onClick={() => setIsOpen(!isOpen)} className={styles['social-share__toggle']} style={{ color: platformColor }}>
                {icon}
                {title}
            </button>
            <div className={styles['social-share__menu']}>
                {platformsData.map((platform: any) => (
                    <button onClick={platform.onClick} key={platform.id} type="button" className={styles['social-share__menu-item']}>
                        {platform.icon}
                        {platform.label}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default SocialShare;
