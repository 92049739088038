import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';

import { Button } from '@/components/Button';
import { useStore } from '@/stores/RootStore';

type IMobileMenuProps = {
    list: JSX.Element;
};

const MobileMenu = observer((props: IMobileMenuProps) => {
    const {
        layoutStore: { mobileMenuIsOpen },
        subscriptionStore: { isLoggedIn, logOut }
    } = useStore();
    const router = useRouter();

    return (
        <AnimatePresence initial={false} exitBeforeEnter={true}>
            {mobileMenuIsOpen && (
                <motion.div
                    variants={{
                        hidden: { opacity: 0 },
                        enter: { opacity: 1 },
                        exit: { opacity: 0 },
                    }}
                    initial="hidden"
                    transition={{ duration: '0.5' }}
                    animate="enter"
                    exit="exit"
                    style={{ height: '100vh' }}
                    className={'fixed inset-0 bg-black p-4'}
                >
                    <motion.div
                        variants={{
                            hidden: { opacity: 1, y: -20 },
                            enter: { opacity: 1, y: 0 },
                            exit: { opacity: 1, y: 0 },
                        }}
                        initial="hidden"
                        transition={{ duration: '0.5' }}
                        animate="enter"
                        exit="exit"
                        className={'pt-44'}
                    >
                        {props.list}
                    </motion.div>

                    <motion.div
                        variants={{
                            hidden: { opacity: 0, y: 20 },
                            enter: { opacity: 1, y: 0 },
                            exit: { opacity: 0, y: 0 },
                        }}
                        initial="hidden"
                        transition={{ duration: '0.5' }}
                        animate="enter"
                        exit="exit"
                        className={'absolute inset-x-16 bottom-16'}
                    >
                        {!isLoggedIn ? <Button onClick={() => router.push('/login')} fullWidth mobile>
                            Login
                        </Button> : <Button onClick={async () => logOut()} fullWidth mobile>
                            Logout
                        </Button>}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
});

export { MobileMenu };
