import className from 'classnames';

import NextImage from '@/components/image';
import type { ComponentSectionsDataTilesGrid } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import { If } from '@/utils/If';

export type IDataGridSectionOld = {
    data: ComponentSectionsDataTilesGrid;
};

const DataGridSectionOld = (props: IDataGridSectionOld) => (
    <Section padding={'pt-64 mt-5 w-full px-16'} title={props.data.Title}>
        <div className={'wrapper'}>
            <If condition={props.data.Tiles.length > 0}>
                {props.data.Tiles.map((item: any, i: number) => {
                    const {
                        Image, Text, Link = null, Title
                    } = item;

                    return (
                        <div className={'blockClass m-[1rem] flex w-[23%] flex-col-reverse justify-between bg-bpm-darkGray px-16 fourFourthy:flex-[48%]'} key={`BlockItem-${i}`}>
                            <div className={className('text mb-16 mt-[3.7rem]', !Link && 'h-fit')}>
                                <div>
                                    <h2>{Title}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: Text }} />
                                </div>
                                <If condition={Link}>
                                    <a className={'action'}>Get Started</a>
                                </If>
                            </div>
                            <div className={'image relative h-[33.4rem] w-[100%]'}>
                                <NextImage
                                    src={''}
                                    priority
                                    media={Image}
                                    alt={Title}
                                    layout="fill"
                                    objectFit="contain"
                                />
                            </div>
                        </div>
                    );
                })}
            </If>
        </div>
        <style jsx>
            {`
        .wrapper {
            -ms-box-orient: horizontal;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
        }

        .wrapper h2 {
          @apply text-2xl;
        }

        .wrapper p {
          @apply text-xl opacity-70 leading-[2.6rem] mt-4;
        }

        .text {
          @apply border-l-2 border-white border-opacity-20 pl-4;
        }

        .Small {
          grid-row: span 2;
        }

        .Small .text {
          max-width: 30rem;
          @apply flex flex-col justify-between mr-32;
          padding-left: 1.6rem;
        }

        .Big {
          grid-row: span 4;
        }

        .Big .image {
          min-height: 54.3rem;
          margin-top: 7.5rem;
        }

        .Big .text {
          max-width: 39rem;
          @apply mb-32;
        }

        .action {
          @apply text-xl underline cursor-pointer opacity-0;
        }

        .blockClass:hover .action {
          @apply opacity-100;
        }
      `}
        </style>
    </Section>
);

export { DataGridSectionOld };
