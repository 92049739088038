import { wrap } from '@motionone/utils';
import {
    motion, useAnimationFrame, useMotionValue, useScroll, useSpring, useTransform, useVelocity
} from 'framer-motion';
import { title } from 'process';
import React, { useCallback, useRef, useState } from 'react';

import type { ButtonRef } from '@/components/Button';
import { Button } from '@/components/Button';
import NextImage from '@/components/image';
import type {
    ComponentElementsSlidingCover, ComponentLinksButton, ComponentSectionsSlidingCoversHero, Maybe
} from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import { useStore } from '@/stores/RootStore';
import { dynamicLineBreak } from '@/utils/dynamicLineBreak';

export interface ISlidingCoversHeroProps {
    data: ComponentSectionsSlidingCoversHero
}

function split(array: Maybe<ComponentElementsSlidingCover>[], n: number) {
    const [...arr] = array;
    const res = [];
    while (arr.length) {
        res.push(arr.splice(0, n));
    }
    return res;
}

interface ParallaxProps {
    childrens: Array<Maybe<ComponentElementsSlidingCover>> | Maybe<Array<Maybe<ComponentElementsSlidingCover>>> | undefined;
    baseVelocity: number;
    isMobile: boolean;
    horizontal?: boolean;
}

function ParallaxCol({
    childrens, baseVelocity = 100, isMobile = false, horizontal = false
}: ParallaxProps) {
    const baseY = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
        damping: 50,
        stiffness: 400
    });
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
        clamp: false
    });
    const y = useTransform(baseY, (v) => `${wrap(horizontal ? -20 : -15, horizontal ? -45 : -40, v)}%`);

    const directionFactor = useRef<number>(1);
    useAnimationFrame((_t, delta) => {
        let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

        if (velocityFactor.get() < 0) {
            directionFactor.current = -1;
        } else if (velocityFactor.get() > 0) {
            directionFactor.current = 1;
        }

        moveBy += directionFactor.current * moveBy * velocityFactor.get();
        baseY.set(baseY.get() + moveBy);
    });

    return (
        <div className={`parallax text-bpm-white ${!horizontal ? '' : ''}`}>
            <motion.div className={`scroller flex ${!horizontal ? 'w-[20rem] flex-col' : 'flex-row'} `} style={horizontal ? { x: y } : { y }}>
                {[...(childrens as any), ...(childrens as any), ...(childrens as any), ...(childrens as any)]?.map((item: ComponentElementsSlidingCover) => <div key={`R-${item?.id}`}>
                    <div className={`relative my-[1.2rem] ${horizontal ? 'mx-[1.6rem]' : ''}`} style={{
                        width: isMobile ? 144 : 200, height: isMobile ? 144 : 200
                    }}>
                        <NextImage
                            src=""
                            media={item?.cover}
                            alt={title}

                            width={200}
                            height={200}
                            objectFit='fill'
                            priority
                        />
                        <div onClick={() => { item?.link ? window.location.href = item?.link : null; }} className='group pointer-events-auto absolute inset-0 z-20 flex cursor-pointer items-center justify-center bg-black bg-opacity-0 transition-all ease-in-out hover:bg-opacity-40'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" className='opacity-0 transition-all ease-in-out group-hover:opacity-100'>
                                <path fillRule="evenodd" clipRule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM30 20L14 10V30L30 20Z" fill="#E6EFE9" />
                            </svg>
                        </div>
                    </div>
                </div>)}
            </motion.div>
            <style jsx>
                {`
          .parallax {
            overflow: hidden;
            
            display: flex;
          }
        `}
            </style>
        </div>
    );
}

const SearchField = ({ mainButton, color, library }: { mainButton: ComponentLinksButton; color: string; library: string; }) => {
    const [searchFocused, setSearchFocused] = useState<boolean>(false);
    const [buttonLeft, setButtonLeft] = useState<number>(0);
    const [search, setSearch] = useState<string>('');
    const {
        layoutStore: { isMobile },
    } = useStore();

    const getLibraryLink = useCallback(
        (_library: string, _search: string) => {
            switch (_library) {
                case 'Latino':
                    return `https://app.bpmsupreme.com/d/search?searchTerm=${_search}&library=latin`;
                case 'Create':
                    return `https://app.bpmcreate.com/search?searchTerm=${_search}`;
                case 'Supreme':
                default:
                    return `https://app.bpmsupreme.com/d/search?searchTerm=${_search}`;
            }
        },
        [],
    );

    const getLink = useCallback(
        (_library: string) => {
            switch (_library) {
                case 'Latino':
                    return 'https://app.bpmsupreme.com/d&library=latin';
                case 'Create':
                    return 'https://app.bpmcreate.com';
                case 'Supreme':
                default:
                    return 'https://app.bpmsupreme.com/d';
            }
        },
        [],
    );

    return <div className={`relative mx-auto ${searchFocused ? 'w-[100%]' : 'w-[264px]'} lg:mx-0`}>
        <form onSubmit={() => {
            window.open(`${getLibraryLink(library, search)}`, '_blank');
        }}>
            <input value={search} onChange={(e) => { setSearch(e.target.value); }} style={{ background: "url('/assets/icons/search-icon.svg') no-repeat 10px 50% #2A2A2A", left: buttonLeft }} placeholder='Search' className={`duration-400 absolute w-[12.4rem] rounded-none bg-[#2A2A2A] py-[1.2rem] pl-[4rem] pr-[1.6rem] text-[1.6rem] outline-none ring-0 transition-all ease-in-out  ${isMobile ? 'focus:!left-0 focus:w-[100%] lg:focus:w-[35.2rem]' : 'hover:!left-0 hover:w-[100%] lg:hover:w-[35.2rem]'}`} onMouseEnter={() => !isMobile && setSearchFocused(true)} onMouseLeave={() => !isMobile && setSearchFocused(false)} onFocus={() => isMobile && setSearchFocused(true)} onBlur={() => isMobile && setSearchFocused(false)} type="text" />
        </form>
        <Button onClick={() => window.open(getLink(library))} ref={async (ref: ButtonRef) => {
            setButtonLeft((await ref?.getWidth() as number) + 14);
        }} style={{ background: color }} className={`text-[1.6rem] !transition-none ${isMobile ? 'focus:!bg-black' : 'hover:!bg-black'} lg:!transition-all ${searchFocused ? 'opacity-0' : 'opacity-1'}`}>{mainButton.text}</Button>
    </div>;
};

const SlidingCoversHero = (props: ISlidingCoversHeroProps) => {
    const {
        layoutStore: { isMobile },
    } = useStore();

    const {
        MainTitle, MainButton, Covers, Library
    } = props.data;

    const getLibraryColor = useCallback(
        (library: string) => {
            switch (library) {
                case 'Supreme':
                case 'Latino':
                    return '#FE3C26';
                case 'Create':
                    return '#1AF085';
                default:
                    break;
            }
            return 'FE3C26';
        },
        [],
    );

    return (
        <Section
            padding={
                ' w-full text-[#E6EFE9]'
            }
            classNames='max-w-[144rem] w-full'
        >
            <div className='relative mt-[12.8rem] flex w-full flex-col justify-center lg:mt-0 lg:h-[84.7rem] lg:flex-row lg:px-[6rem]' style={{ overflow: 'hidden' }}>
                <div className='flex w-full flex-col justify-center space-y-[3.2rem] px-[2rem] md:px-[6rem] lg:w-1/2 lg:px-[0rem]  lg:pl-[2rem]'>

                    <div className='flex flex-col items-center lg:items-start'>
                        {/* <img alt="bpm supreme" width={44} height={44} src="https://bpm-music-cms.s3.amazonaws.com/app_Icon_d67b24a1b8.png" /> */}
                        <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group 316124595">
                                <rect id="160208_r27647 9" y="0.5" width="44" height="44" rx="10.5" fill={getLibraryColor(Library)} />
                                <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M31.4711 19.6028C31.1473 20.7866 30.4616 21.8447 29.5062 22.6347C30.463 23.4244 31.1499 24.4828 31.4746 25.6673C31.7992 26.8518 31.7459 28.1054 31.3219 29.2592C30.8978 30.4131 30.1234 31.4117 29.103 32.1205C28.0825 32.8293 26.8651 33.2142 25.6148 33.2233H14.2V30.1858H25.5036C26.319 30.1853 27.1008 29.8675 27.6775 29.3021C28.2542 28.7367 28.5787 27.9699 28.5797 27.1701C28.5797 25.4459 26.9614 24.1566 25.5103 24.1526V27.1734H14.2048V24.1377H25.5036V21.1392H14.2032V18.1035H25.5087V21.1169C26.9602 21.1139 28.5797 19.8243 28.5797 18.0994C28.5777 17.3008 28.2533 16.5355 27.6775 15.971C27.1017 15.4065 26.3214 15.0888 25.5073 15.0873H14.2037V12.0498H25.6148C26.8642 12.0598 28.0806 12.445 29.1001 13.1536C30.1196 13.8622 30.8934 14.8602 31.3173 16.0132C31.7412 17.1662 31.7949 18.419 31.4711 19.6028Z" fill="black" />
                            </g>
                        </svg>
                        <span className='mt-[0.8rem]'>BPM {Library === 'Latino' ? 'Supreme' : Library}</span>
                    </div>

                    {MainTitle && <h1 className='text-center font-gravity text-[9.6rem] leading-[8.4rem] lg:text-left' dangerouslySetInnerHTML={{ __html: dynamicLineBreak(MainTitle, isMobile) }} />}

                    <SearchField library={Library} color={getLibraryColor(Library)} mainButton={MainButton} />

                </div>
                <div className='relative ml-[2rem] w-full min-w-[66rem] lg:w-1/2'>
                    <div className='hidden flex-row space-x-[3.2rem]  lg:flex'>
                        {split(Covers, 5).map((covers, index) => <ParallaxCol key={''} baseVelocity={index === 0 ? -0.5 : index === 1 ? 0.5 : -1} isMobile={isMobile} childrens={covers} />)}
                    </div>
                    <div className='mb-[3.2rem] mt-[12.8rem] flex flex-col lg:hidden lg:flex-row'>
                        {[-1, 1, -2].map((velocity: number, index: number) => <ParallaxCol horizontal={true} key={`PXM-${velocity}`} baseVelocity={velocity} isMobile={isMobile} childrens={Covers.splice(index * (Covers.length / 3), index + 1 * (Covers.length / 3))} />)}
                    </div>
                    <div className='pointer-events-none absolute inset-x-0 bottom-0 z-10 hidden h-[43.9rem] w-full lg:block' style={{ background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)' }} />
                </div>

            </div>
        </Section>
    );
};

export default SlidingCoversHero;
