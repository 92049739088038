import type { ReactNode } from 'react';

type IFooterLinksProps = {
    title?: ReactNode;
    children: ReactNode;
};

const FooterLinks = (props: IFooterLinksProps) => (
    <div className="footer-links text-center sm:text-left ">
        {props.title && (
            <div className={'text-[1.6rem] leading-[1.8rem] text-primary-lightWhite opacity-50'}>{props.title}</div>
        )}

        <nav className="mt-[2.0rem]">
            <ul>{props.children}</ul>
        </nav>

        <style jsx>
            {`
        .footer-links :global(li) {
          @apply mb-[2rem];
        }
      `}
        </style>
    </div>
);

export { FooterLinks };
