import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import { fetchAPI } from '@/api/fetch';
import { GraphQlAPI } from '@/api/graphql';
import Sections from '@/components/sections';
import Seo from '@/components/seo';
import { useStore } from '@/stores/RootStore';
import { Logo } from '@/templates/Logo';
import { Main } from '@/templates/Main';
import { redirectionTargetToUrl } from '@/utils/redirect';

import FourOhFour from './404';

const DynamicPage = (props: any) => {
    const {
        sections, metadata, preview, global, pageContext, navigation
    } = props;

    const {
        subscriptionStore: { subscriptions, subscriptionsLoaded },
        layoutStore: { setAccent },
    } = useStore();

    const router = useRouter();

    const [redirectDeterminationIsDone, setRedirectDeterminationIsDone] = useState<boolean>(false);

    useEffect(() => {
        setRedirectDeterminationIsDone(false);
    }, [pageContext?.slug]); // need to reset when page changes, since state persists throughout navigation

    useEffect(() => {
        if (pageContext && subscriptionsLoaded) {
            const { RedirectOnSubscription, RedirectionTarget } = pageContext;
            if (RedirectOnSubscription === 'Artist') {
                window.location.replace(redirectionTargetToUrl(RedirectionTarget));
            } else if (RedirectOnSubscription && RedirectionTarget && subscriptions && subscriptions[RedirectionTarget.toLowerCase() as 'supreme'] && subscriptions[RedirectionTarget.toLowerCase() as 'supreme'].has_membership) {
                if (process.env.NEXT_PUBLIC_REDIRECT_CREATE_URL && process.env.NEXT_PUBLIC_REDIRECT_CREATE_URL !== '' && process.env.NEXT_PUBLIC_REDIRECT_SUPREME_URL && process.env.NEXT_PUBLIC_REDIRECT_SUPREME_URL !== '') {
                    window.location.replace(redirectionTargetToUrl(RedirectionTarget));
                } else {
                    setRedirectDeterminationIsDone(true);
                }
            } else {
                setRedirectDeterminationIsDone(true);
            }
        }
    }, [pageContext, subscriptions, subscriptionsLoaded]);

    useEffect(() => {
        setAccent(metadata.accent);
    }, [metadata]);

    // Check if the required data was provided
    if (!router.isFallback && !sections?.length) {
        return <FourOhFour />;
    }

    // Loading screen (only possible in preview mode)
    if (router.isFallback) {
        return <div className="container">Loading...</div>;
    }
    // Merge default site SEO settings with page specific SEO settings
    if (metadata.shareImage === null || metadata.shareImage?.data == null) {
        delete metadata.shareImage;
    }
    if (!metadata.FacebookPixeliD) {
        delete metadata.FacebookPixeliD;
    }
    if (!metadata.GoogleTagManagerID) {
        delete metadata.GoogleTagManagerID;
    }
    if (!metadata.ShowZendeskChat || !metadata.ZendeskChatTagId || metadata.ZendeskChatTagId === '') {
        delete metadata.ShowZendeskChat;
        delete metadata.ZendeskChatTagId;
    }
    const metadataWithDefaults = {
        ...global.attributes.metadata,
        ...metadata,
    };

    if (!redirectDeterminationIsDone) {
        return <div className='flex h-screen w-screen items-center justify-center'>
            <Seo metadata={metadataWithDefaults} />
            <Logo showText={false} width={25} height={25} />
        </div>;
    }

    return (
        <Main
            key={router.asPath}
            showNavigation={pageContext.ShowNavigation}
            footer={pageContext.ShowFooter}
            global={global}
            navigation={navigation}
            pageContext={pageContext}
        >
            {/* Add meta tags for SEO */}
            <Seo metadata={metadataWithDefaults} />
            {/* Display content sections */}
            <Sections global={global} sections={sections} preview={preview} />
        </Main>
    );
};

export async function getStaticPaths(_context: any) {
    // Get all pages from Strapi
    const pages = await fetchAPI('/pages', {
        locale: 'en',
        fields: ['slug', 'locale'],
    });

    const paths = pages.data.map((page: any) => {
        const { slug } = page.attributes;
        // Decompose the slug that was saved in Strapi
        const slugArray = !slug ? false : slug.split('/');

        return {
            params: { slug: slugArray },
            // Specify the locale to render
            // locale,
        };
    });

    return { paths, fallback: 'blocking' };
}

export async function getStaticProps(context: any) {
    const { params, locale, preview = null } = context;

    const globalLocale = await GraphQlAPI.getGlobalData(locale ?? 'en');

    // Fetch pages. Include drafts if preview mode is on
    const pageData = await GraphQlAPI.getPageData({
        slug: (!params.slug ? [''] : params.slug).join('/'),
        // locale,
        preview,
    });

    if (pageData == null) {
        // Giving the page no props will trigger a 404 page
        return { props: {} };
    }

    // We have the required page data, pass it to the page component
    const {
        contentSections,
        metadata,
        RedirectOnSubscription,
        RedirectionTarget,
        // localizations,
        slug,
        ShowFooter,
        ShowNavigation,
    } = pageData.attributes!;

    const pageContext = {
        // locale,
        // locales,
        // defaultLocale,
        slug,
        RedirectOnSubscription,
        RedirectionTarget,
        // localizations,
        ShowFooter,
        ShowNavigation,
    };

    // const localizedPaths = getLocalizedPaths(pageContext);
    return {
        props: {
            navigation: {
                main: globalLocale.mainNavigation,
                footer: globalLocale.footerNavigation,
            },
            preview,
            sections: contentSections,
            metadata,
            global: globalLocale.global?.data,
            pageContext: {
                ...pageContext,
                // localizedPaths,
            },
        },
        revalidate: 60,
    };
}

export default observer(DynamicPage);
