import Responsive from '@/utils/Responsive';

import type { IDataGridSection } from './DataGridSection';
import { DataGridSection } from './DataGridSection';
import { DataGridSectionMobile } from './DataGridSectionMobile';

const DataGridSectionWrapper = (props: IDataGridSection) => (
    <Responsive
        desktop={<DataGridSection {...props} />}
        mobile={<DataGridSectionMobile {...props} />}
    />
);
export default DataGridSectionWrapper;
