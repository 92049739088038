import { useRouter } from 'next/router';

const PreviewModeBanner = () => {
    const router = useRouter();
    const exitURL = '/api/exit-preview';

    return (
        <div className="radius fixed inset-x-0 top-[50%] z-50 w-[5rem] flex-col content-center justify-center rounded-md bg-red-600 pl-2 pt-2 pb-4 align-middle uppercase tracking-wide text-red-100 shadow-2xl">
            <img
                src={'assets/icons/eye-svgrepo-com.svg'}
                width={40}
                className={'mb-2 '}
            />
            <img
                onClick={() => {
          confirm('Are you sure you want to exit preview mode?')
            && router.push(exitURL);
                }}
                src={'assets/icons/close-svgrepo-com.svg'}
                width={30}
                className={'ml-1.5 cursor-pointer'}
            />
        </div>
    );
};
export default PreviewModeBanner;
