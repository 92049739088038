import { useState } from 'react';

type IMobileMenuIconProps = {
    onMenuOpenTapped: any;
};

const MobileMenuIcon = (props: IMobileMenuIconProps) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <div
            className={
                'hamburgerWrapper z-50 border-2 border-bpm-white border-opacity-50'
            }
        >
            <div
                className={`hamburger ${open && 'open'}`}
                onClick={() => {
          setOpen(!open);
          props.onMenuOpenTapped();
                }}
            >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <style jsx>
                {`
          .hamburgerWrapper {
            padding: 1.2rem 1rem;
          }

          .hamburger {
            width: 2.2rem;
            height: 1.5rem;
            position: relative;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.5s ease-in-out;
            -moz-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
            cursor: pointer;
          }

          .hamburger span {
            display: block;
            position: absolute;
            height: 0.2rem;
            width: 100%;
            background: #ffffff;
            border-radius: 0.3rem;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;
          }

          .hamburger span:nth-child(1) {
            top: 0rem;
          }

          .hamburger span:nth-child(2),
          .hamburger span:nth-child(3) {
            top: 0.7rem;
          }

          .hamburger span:nth-child(4) {
            top: 1.4rem;
          }

          .hamburger.open span:nth-child(1) {
            top: 1.8rem;
            width: 0%;
            left: 50%;
          }

          .hamburger.open span:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          .hamburger.open span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }

          .hamburger.open span:nth-child(4) {
            top: 1.8rem;
            width: 0%;
            left: 50%;
          }
        `}
            </style>
        </div>
    );
};

export { MobileMenuIcon };
