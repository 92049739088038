type IFooterTwoRowsCopyrightProps = {
    copyrights: string;
};

const FooterCopyright = (props: IFooterTwoRowsCopyrightProps) => (
    <>
        <div className=" mx-auto grid max-w-7xl grid-cols-1 items-center justify-center gap-y-5  px-14 pb-[3.5rem] pt-8 md:mt-[6.4rem] md:px-0 md:pb-[0.2rem] md:pt-14 lg:grid-cols-2">
            <div className="text-[1.6rem] leading-[1.8rem] text-bpm-white">
                {`© ${new Date().getFullYear()} ${props.copyrights}`}
            </div>

            <div className="hidden text-center text-sm lg:text-right">
                Location xxxx
            </div>
        </div>
    </>
);

export { FooterCopyright };
