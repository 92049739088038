import { observer } from 'mobx-react';

import NextImage from '@/components/image';
import SeoableText from '@/components/SeoableText';
import type { ComponentSectionsLogoBoxes } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';

export type ILogoBoxesProps = {
    data: ComponentSectionsLogoBoxes;
};

const LogoBoxes = observer((props: ILogoBoxesProps) => (
    <div className={'md:m-16'}>
        <Section
            padding={
                'mt-36  md:w-full'
            }
        >
            <SeoableText element="h3" className='mb-[8rem] text-center text-[5.6rem] leading-[6.4rem]'>{props.data.LogoBoxtitle}</SeoableText>
            <div
                className={
                    'flex w-full flex-row justify-between text-white'
                }
            >
                {props.data.Logos.map((logo, index: number) => <div onClick={() => logo?.LogoLink && window.open(logo?.LogoLink, '_blank')} style={{ cursor: logo?.LogoLink ? 'pointer' : 'default' }} className='logoBox mx-[1.2rem] h-full max-h-[43rem] w-full max-w-[43rem] ' key={`As-${index}`}>
                    <div className={'relative bg-[#151918]'} style={{ opacity: 0.5 }}>
                        <NextImage
                            src={''}
                            priority
                            media={logo?.LogoImage}
                            alt={logo?.LogoName ?? ''}
                            width={430}
                            height={430}
                            layout="responsive"
                            objectFit="contain"
                            objectPosition="middle"
                        />
                    </div>
                    <SeoableText element="span" className='mt-[2rem] block text-[3.2rem] leading-[4rem]'>{logo?.LogoName}</SeoableText>
                </div>)}

            </div>
        </Section>
        <style jsx>
            {`
            :global(.logoBox div) {
                transition: all 0.2s ease-in-out;
            }
            :global(.logoBox:hover > div ) {
                opacity: 1!important;
            }
            `}
        </style>
    </div>
));

export { LogoBoxes };
