import className from 'classnames';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react';
import Link from 'next/link';
import { useRef, useState } from 'react';
import { useElementSize } from 'usehooks-ts';

import type { ICarouselHandle } from '@/components/Carousel/Carousel';
import { Carousel } from '@/components/Carousel/Carousel';
import NextImage from '@/components/image';
import { Section } from '@/layouts/Section';
import type { IDataGridSection } from '@/modules/DataGridSection/DataGridSection';
import { useStore } from '@/stores/RootStore';
import { If } from '@/utils/If';

const DataGridSectionMobile = observer((props: IDataGridSection) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
    const carouselRef = useRef<ICarouselHandle>(null);
    const {
        layoutStore: { accent },
    } = useStore();
    const [squareRef, { width }] = useElementSize();

    return (
        <Section
            titleClass={'ml-[-2rem] mb-[-3.4rem]'}
            padding={'pt-8 md:pt-36 mt-5 ml-8'}
            title={props.data.BigTitle}
            smallTitle={props.data.SmallTitle}
            classNames={'mt-[10rem] md:mt-[0]'}
        >
            <div className={'wrapper grid '}>
                <Carousel
                    initialWidth={95.5}
                    margin={8}
                    ref={carouselRef}
                    onChange={setActiveSlideIndex}
                >
                    {[props.data.TopLeftCard, props.data.TopRightCard, props.data.BottomLeftCard, props.data.BottomRight].map((item: any, i: number) => {
                        const {
                            Image, Text, Link: link = null, Title, MobileImage
                        } = item;
                        const blockClass = className(
                            'flex',
                            'justify-between',
                            'bg-bpm-darkGray',
                            'px-[3.0rem] pt-[3.0rem] pb-[3.0rem]',
                            'flex-col big'
                        );
                        return (
                            <div className={blockClass} key={`BlockItem-${i}`}>
                                <div className={className('text', link && 'h-fit')}>
                                    <div>
                                        <h2 className={'span'}>{Title}</h2>
                                        <p className='mt-[1rem] text-[1.7rem] leading-[2.4rem] text-bpm-white opacity-50' dangerouslySetInnerHTML={{ __html: Text }} />
                                    </div>
                                    <If condition={link}>
                                        <Link href={link}>
                                            <a className={'action mt-[1rem] flex cursor-pointer items-center !text-[1.6rem] leading-[2.2rem]'}>Learn More <img
                                                src={'/assets/icons/leftWhiteArrow.svg'}
                                                className={'ml-[1rem] inline-block rotate-180'}
                                                width={8}
                                                height={4}
                                            /></a>
                                        </Link>
                                    </If>
                                </div>
                                <div style={{ maxWidth: '31.2rem' }}>
                                    <div
                                        ref={squareRef}
                                        className={'image relative mt-7 w-full'}
                                        style={{ width: '100%', height: width, maxHeight: '32.2rem' }}
                                    >
                                        <NextImage
                                            src={''}
                                            priority
                                            media={MobileImage || Image}
                                            alt={Title}
                                            layout="fill"
                                            className='aspect-[1/1] '
                                            objectFit="cover"
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Carousel>
                <div className={'navigation'}>
                    {[props.data.TopLeftCard, props.data.TopRightCard, props.data.BottomLeftCard, props.data.BottomRight].map((_, i: number) => (
                        <motion.div
                            onClick={() => carouselRef.current?.slideTo(i)}
                            animate={
                                activeSlideIndex === i
                                    ? { backgroundColor: accent || '#FF2F00', width: 40 }
                                    : { width: 15 }
                            }
                            transition={{ ease: 'easeInOut', duration: 0.4 }}
                            key={i}
                        />
                    ))}
                </div>
            </div>
            <style jsx>
                {`
          .wrapper {
            margin-bottom: 7.4rem;
          }

          :global(.wrapper h2) {
            @apply text-base;
          }

          :global(.wrapper p) {
            @apply text-base opacity-70 leading-[2.2rem] mt-[0.8rem] ;
          }

          :global(.text) {
            @apply border-white;
          }

          :global(.big) {
            grid-row: span 4;
          }

          :global(.big .text) {
            max-width: 39rem;
          }

          :global(.action) {
            @apply text-xl underline cursor-pointer;
          }

          :global(.navigation) {
            @apply flex flex-row justify-center my-12 ml-[-2rem];
          }

          :global(.navigation div) {
            width: 1.5rem;
            height: 0.2rem;
            @apply bg-bpm-white bg-opacity-30 m-2;
          }
        `}
            </style>
        </Section>
    );
});

export { DataGridSectionMobile };
