/* eslint-disable max-len */
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import React from 'react';

import { Button } from '@/components/Button';
import NextImage from '@/components/image';
import SeoableText from '@/components/SeoableText';
import type {
    ComponentSectionsContentHero, GlobalEntity
} from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import { useStore } from '@/stores/RootStore';

type IContentHeroProps = {
    data: ComponentSectionsContentHero;
    global: GlobalEntity;
};

const ContentHero = observer((props: IContentHeroProps) => {
    const {
        layoutStore: { isMobile },
    } = useStore();
    const ref = React.useRef(null);
    const router = useRouter();
    const getImage = React.useCallback(() => (isMobile && props.data.BigMainImageMobile && props.data.BigMainImageMobile.data ? props.data.BigMainImageMobile : props.data.BigMainImage), [isMobile, props.data.BigMainImageMobile, props.data.BigMainImage]);

    const image = getImage();

    return (
        <div ref={ref} className={`overflow-hidden ${props.data.FullWidthBg?.data ? '' : 'md:m-16'}`}>
            <Section
                fullWidth={props.data.FullWidthBg?.data !== undefined}
                padding={
                    'mt-36 md:mx-8 md:mx-auto px-8 pt-[3.5rem]  md:px-16 md:py-16 md:w-full relative '
                }
            >
                {props.data.FullWidthBg?.data && <div className='absolute inset-x-[-2rem] top-[50rem] bottom-0 md:top-[64rem]'>
                    <NextImage
                        src={''}
                        priority
                        media={props.data.FullWidthBg}
                        alt={props.data.TitleBig || ''}
                        width={props.data.FullWidthBg.data.attributes?.width!}
                        height={props.data.FullWidthBg.data.attributes?.height!}
                        layout={isMobile ? 'fill' : 'responsive'}
                        objectFit='cover'
                        objectPosition='top'
                    />
                </div>}
                <div
                    className={
                        'flex w-full flex-col justify-center text-black md:flex-row'
                    }
                >
                    <div className={'relative h-fit w-full max-w-[111.8rem] text-center'}>
                        <SeoableText element="h3" dangerouslySetInnerHTML={{ __html: props.data.TitleBig || '' }} className={`mb-[4rem] text-center ${props.data.StyledTitle ? 'font-gravity text-[6.4rem] leading-[5.8rem] md:text-[14rem] md:leading-[13.2rem]' : 'text-[4rem] leading-[4rem] md:text-[5.6rem] md:leading-[5.6rem]'} px-6 text-white md:px-0`} />
                        {props.data.TitleSmall && <SeoableText element="h4" className='mb-[5rem] px-24 text-center text-[1.8rem] leading-[2.8rem] text-[#e6f0e980] md:px-0  md:text-[2.2rem] md:leading-[2.8rem]' dangerouslySetInnerHTML={{ __html: props.data.TitleSmall }} />}
                        <div className='mb-[8rem] flex justify-center'>
                            {props.data.UseAppStoreLinks ? <>
                                <Button onClick={() => window.open(props.global.attributes?.iOSStoreLink ?? '', 'blank')} className=''>App Store</Button>
                                <Button onClick={() => window.open(props.global.attributes?.GooglePlayStoreLink ?? '', 'blank')} className='ml-[2rem]'>Google Play</Button>
                            </> : <>
                                {props.data.Button && <Button onClick={() => router.push(props.data?.Button?.page?.data?.attributes?.slug!)} className=''>{props.data.Button.text}</Button>}
                                {props.data.Button2 && <Button onClick={() => router.push(props.data?.Button2?.page?.data?.attributes?.slug!)} className='ml-[2rem]'>{props.data.Button2.text}</Button>}
                            </>}

                        </div>
                        {image && image.data
                            && <div className={` ${image.data?.attributes?.width! > image!.data?.attributes?.height! ? 'w-[90vw] md:w-full' : 'w-[90vw] md:w-[26vw]'} ${props.data.FullWidthBg?.data ? 'mt-[20rem]' : ''} mx-auto mb-[10rem] px-0 md:mb-0  md:px-0 `}>
                                <NextImage
                                    src={''}
                                    priority
                                    media={image}
                                    alt={props.data.TitleBig || ''}
                                    width={image.data!.attributes?.width!}
                                    height={image.data!.attributes?.height!}
                                    layout="responsive"
                                    objectFit='contain'
                                    objectPosition='top'
                                />
                            </div>
                        }
                    </div>
                </div>
            </Section>
        </div>
    );
});

export { ContentHero };
