import type { PanHandlers } from 'framer-motion';
import { motion, useMotionValue } from 'framer-motion';
import React, { useCallback, useImperativeHandle } from 'react';

import { Slider } from '@/components/Carousel/Slider/Slider';

import { useAutoplay } from './useAutoplay';
import { useOnChange } from './useOnChange';
import type { OnPan } from './usePan';
import { usePan } from './usePan';
import { animateSpring } from './utils';

const MotionSlider = motion(Slider);

export interface ICarouselHandle {
    slideNext: () => void;
    slidePrev: () => void;
    slideTo: (index: number) => void;
}

interface Props {
    autoplayInterval?: number;
    children?: React.ReactNode;
    className?: string;
    count?: number;
    initialWidth?: number;
    draggable?: boolean;
    margin?: number;
    style?: React.CSSProperties;
    onChange?: (index: number) => void;
}

export const Carousel = React.forwardRef<ICarouselHandle, Props>(
    (
        {
            autoplayInterval = 0,
            count = 1,
            children,
            draggable = true,
            margin = 0,
            onChange,
            initialWidth = 100,
            ...props
        },
        ref: React.Ref<ICarouselHandle>
    ) => {
        const sliderRef = React.useRef<HTMLDivElement>(null);
        const index = useMotionValue(0);
        const [isAnimating, setIsAnimating] = React.useState(false);

        useOnChange({
            childrenCount: React.Children.count(children),
            index,
            onChange,
        });

        const autoplay = useAutoplay(index, autoplayInterval);

        useImperativeHandle(
            ref,
            () => ({
                slideNext: (): void => {
                    if (isAnimating) {
                        return;
                    }
                    setIsAnimating(true);
                    autoplay.start();

                    const roundIndex = Number(index.get().toFixed(4));

                    animateSpring(index, Math.ceil(roundIndex + 1));
                    setIsAnimating(false);
                },
                slidePrev: (): void => {
                    if (isAnimating) {
                        return;
                    }
                    setIsAnimating(true);
                    autoplay.start();

                    const roundIndex = Number(index.get().toFixed(4));

                    animateSpring(index, Math.floor(roundIndex - 1));
                    setIsAnimating(false);
                },
                slideTo: (newIndex: number): void => {
                    if (isAnimating) {
                        return;
                    }
                    setIsAnimating(true);
                    autoplay.start();

                    animateSpring(index, newIndex);
                    setIsAnimating(false);
                },
            }),
            [autoplay, index]
        );

        const panHandlers = usePan({
            count,
            index,
            margin,
            ref: sliderRef,
        });

        const onPanStart: OnPan = useCallback(
            (...args) => {
                autoplay.stop();

                panHandlers.onPanStart(...args);
            },
            [autoplay, panHandlers]
        );

        const onPanEnd: OnPan = useCallback(
            (...args) => {
                autoplay.start();

                panHandlers.onPanEnd(...args);
            },
            [autoplay, panHandlers]
        );

        let panProps: PanHandlers = {};
        if (draggable) {
            panProps = {
                onPanStart,
                onPan: panHandlers.onPan,
                onPanEnd,
            };
        }

        return (
            <MotionSlider
                ref={sliderRef}
                index={index}
                count={count}
                margin={margin}
                initialWidth={initialWidth}

                {...panProps}
                {...props}
            >
                {children}
            </MotionSlider>
        );
    }
);

Carousel.displayName = 'Carousel';
