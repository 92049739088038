import Link from 'next/link';

import { Accordion } from '@/components/Accordion';
import type { IFooterProps } from '@/templates/Footer';

import { Section } from '../layouts/Section';
import { FooterCopyright } from '../modules/footer/FooterCopyright';
import { FooterLinks } from '../modules/footer/FooterLinks';
import { Logo } from './Logo';

const FooterMobile = (props: IFooterProps) => (
    <Section
        background={'mobile-footer bg-bpm-darkGray my-8 md:my-16 mx-8 md:mx-0'}
        padding={' px-0'}
    >
        <div className="mx-auto flex max-w-7xl flex-col justify-between px-[3rem] pt-[3.9rem] ">
            <div className={'mr-14'}>
                <Logo width={27} height={33} />
            </div>
            <div className="mt-16 flex w-full flex-col justify-around border-t-[0.1rem] border-white border-opacity-20 pt-8 sm:flex-row">
                <Accordion
                    wrapperClasses={
                        'border-b-[0.1rem] border-white border-opacity-20 pb-8 mb-8 text-sm text-bpm-white opacity-90'
                    }
                    items={props.navigation.map((item) => ({
                        title: (
                            <div
                                className={
                                    'faqTitle align-center flex cursor-pointer flex-row justify-between text-[1.6rem] leading-[2.4rem]'
                                }
                            >
                                <h2>{item.title}</h2>
                                <img
                                    className={'rotate-270'}
                                    src={'/assets/icons/leftWhiteArrow.svg'}
                                    width={16}
                                    height={8}
                                />
                            </div>
                        ),
                        content: (
                            <FooterLinks>
                                {item.items?.map((subItem: any, subIndex) => (
                                    <li key={`SubFooterMenuItem-${subIndex}`}>
                                        <Link href={subItem.path}>
                                            <a>{subItem?.title}</a>
                                        </Link>
                                    </li>
                                ))}
                            </FooterLinks>
                        ),
                    }))}
                />
            </div>
        </div>
        <FooterCopyright copyrights={props.copyrights} />
    </Section>
);

export { FooterMobile };
