import type { ImageProps } from 'next/image';
import Image from 'next/image';

import { getStrapiMedia } from '@/utils/media';

export interface NextImageProps extends ImageProps {
    media: any;
}

const NextImage = (props: NextImageProps) => {
    if (!props || !props.media || !props.media.data) {
        return null;
    }
    const { url, alternativeText } = props.media.data.attributes;

    const loader: any = ({ src }: { src: string }) => getStrapiMedia(src);

    // The image has a fixed width and height
    if (props.width && props.height) {
        return (
            <Image loader={loader} alt={alternativeText || ''} {...props} src={url} />
        );
    }

    // The image is responsive
    return (
        <Image {...props} src={url} loader={loader} alt={alternativeText || ''} />
    );
};

export default NextImage;
