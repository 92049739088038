import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import { useState } from 'react';

type IAccordtionItemProps = {
    index: number;
    expanded: boolean | number;
    setExpanded: Function;
    title: any;
    content: JSX.Element;
    className: string;
};

const AccordionItem = (props: IAccordtionItemProps) => {
    const {
        className, index, expanded, setExpanded, title, content
    } = props;
    const isOpen = index === expanded;
    return (
        <div className={className}>
            <motion.header
                initial={false}
                className={isOpen ? 'open' : ''}
                // animate={{ backgroundColor: isOpen ? '#FF0088' : '#0055FF' }}
                onClick={() => setExpanded(isOpen ? false : index)}
            >
                {title}
            </motion.header>
            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, height: 'auto' },
                            collapsed: { opacity: 0, height: 0 },
                        }}
                        className={'overflow-hidden'}
                        transition={{ duration: 0.4 }}
                        // ease: [0.04, 0.62, 0.23, 0.98]
                    >
                        <motion.div
                            variants={{ collapsed: { y: 0 }, open: { y: 0 } }}
                            transition={{ duration: 0.4 }}
                            style={{ transformOrigin: 'top center', padding: 20 }}
                        >
                            {content}
                        </motion.div>
                    </motion.section>
                )}
            </AnimatePresence>
        </div>
    );
};

export type IAccordtionExternalItemProps = {
    title: JSX.Element;
    content: JSX.Element;
};

type IAccordtionProps = {
    items: IAccordtionExternalItemProps[];
    wrapperClasses: string;
};

export const Accordion = (props: IAccordtionProps) => {
    // This approach is if you only want max one section open at a time. If you want multiple
    // sections to potentially be open simultaneously, they can all be given their own `useState`.
    const [expanded, setExpanded] = useState<false | number>(false);

    return (
        <>
            {props.items.map((item, index) => (
                <AccordionItem
                    key={index}
                    className={props.wrapperClasses}
                    index={index}
                    title={item.title}
                    content={item.content}
                    expanded={expanded}
                    setExpanded={setExpanded}
                />
            ))}
        </>
    );
};
