import { observer } from 'mobx-react';
import React from 'react';

import type { ComponentSectionsRichText } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';

type IRichTextPageProps = {
    data: ComponentSectionsRichText;
};

const RichTextPage = observer((props: IRichTextPageProps) => (
    <>
        <Section>
            <div
                className={'rich-text pt-[20rem]'}
                dangerouslySetInnerHTML={{ __html: props.data.content }}
            ></div>
        </Section>
        <style jsx>
            {`
          :global(.rich-text) {
            max-width: 113.5rem;
            margin: 0 auto;
          }
          :global(.rich-text h4) {
            @apply mx-auto mb-[30rem] block max-w-[58rem] text-center text-2xl leading-heroDesc text-bpm-white;
          }
          :global(.rich-text h1) {
            @apply text-center font-gravity text-11xl text-bpm-white;
          }
          :global(.rich-text h2) {
            @apply text-5xl text-bpm-white leading-faqTitle mb-16;
          }
          :global(.rich-text h3) {
            @apply text-2xl text-bpm-white leading-[3.5rem] mb-[2rem] block;
          }
          :global(.rich-text p) {
            @apply text-2xl text-bpm-white leading-[3.5rem] opacity-50 mb-[14rem];
          }

          :global(.rich-text ul) {
            list-style: disc;
            margin-left: 20px;
            @apply mb-[14rem];
          }

          :global(.rich-text li) {
            @apply text-2xl text-bpm-white leading-[3.5rem] opacity-50 pl-5;
          }
        `}
        </style>
    </>
));
export default RichTextPage;
