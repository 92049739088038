import Responsive from '@/utils/Responsive';

import type { IFeaturesScrollBlockProps } from './FeaturesScrollSection';
import { FeaturesScrollSection } from './FeaturesScrollSection';
import { FeaturesScrollSectionMobile } from './FeaturesScrollSectionMobile';
import { FeaturesScrollSectionStatic } from './FeaturesScrollSectionStatic';
import { FeaturesScrollSectionStaticMobile } from './FeaturesScrollSectionStaticMobile';

const FeaturesScrollSectionWrapper = (props: IFeaturesScrollBlockProps) => (
    <Responsive
        desktop={props.data.KeepSliderOnMobile ? <FeaturesScrollSectionStatic {...props} /> : <FeaturesScrollSection {...props} />}
        mobile={props.data.KeepSliderOnMobile ? <FeaturesScrollSectionStaticMobile {...props} /> : <FeaturesScrollSectionMobile {...props} />}
    />
);
export default FeaturesScrollSectionWrapper;
