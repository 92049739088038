import { observer } from 'mobx-react';
import { useMemo } from 'react';

import { useStore } from '@/stores/RootStore';
import { DeviceType } from '@/utils/useDeviceType';

interface ResponsiveLayOutProps {
    mobile?: JSX.Element;
    desktop?: JSX.Element;
    tablet?: JSX.Element;
}

const Responsive = (props: ResponsiveLayOutProps) => {
    const {
        layoutStore: { deviceType },
    } = useStore();
    const ResponsiveComonent = useMemo(() => {
        const { desktop, mobile, tablet } = props;

        switch (deviceType) {
            case DeviceType.Mobile:
                return mobile;
            case DeviceType.Tablet:
                return tablet !== undefined ? tablet : desktop;
            case DeviceType.Desktop:
                return desktop;
            default:
                return null;
        }
    }, [deviceType, props]);

    return ResponsiveComonent || null;
};

export default observer(Responsive);
