import { Accordion } from '@/components/Accordion';
import SeoableText from '@/components/SeoableText';
import type { ComponentSectionsFaq } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';

type IFAQProps = {
    data: ComponentSectionsFaq;
};
// md:pl-[23.5rem]
const FAQ = (props: IFAQProps) => (
    <Section
        padding={
            'faqAccordion mt-[12rem] md:mt-[20rem] p-16 md:p-16 flex  mb-[-2.7rem] md:mb-[15rem] w-full justify-center max-w-standard'
        }
    >
        <div
            style={{ maxWidth: 1225 }}
            className={'flex w-full flex-col justify-between md:flex-row '}
        >
            <div className={'w-full'} style={{ maxWidth: 467 }}>
                <SeoableText element="span"
                    className={
                        'block text-3xl-m leading-sectionTitleMobile tracking-[-0.01rem] md:text-5xl md:leading-faqTitle md:tracking-[-0.1rem]'
                    }
                >
                    {props.data.BigTitle}
                </SeoableText>
                <SeoableText element="span" className={'mt-[1.4rem] block text-[1.8rem] leading-[2rem] opacity-50 md:mt-[2rem] md:text-[2.2rem] md:leading-[2.8rem]'}>
                    {props.data.SmallTitle}
                </SeoableText>
            </div>
            <div
                className={'mt-[5.4rem] w-full md:mt-[-0.5rem]'}
                style={{ maxWidth: 467 }}
            >
                <Accordion
                    wrapperClasses={
                        'border-b-[0.1rem] border-white border-opacity-20 mb-[4rem]'
                    }
                    items={props.data.questions?.map((item, i) => ({
                        title: (
                            <div
                                key={i}
                                className={
                                    'faqTitle align-center flex cursor-pointer flex-row justify-between  leading-[3rem] '
                                }
                            >
                                <SeoableText element="h2" className={'mb-[3.2rem] pb-[0rem] text-[1.8rem] leading-[2.0rem] md:mb-[1rem] md:pb-[0.2rem] md:text-[2rem] md:leading-[3.0rem]'}>{item?.Question}</SeoableText>
                                <img
                                    className={'w-[2rem] rotate-270'}
                                    src={'/assets/icons/leftWhiteArrow.svg'}
                                    width={25}
                                    height={28}
                                />
                            </div>
                        ),
                        content: <SeoableText element="div">{item?.Answer}</SeoableText>,
                    }))}
                />
            </div>
        </div>
    </Section>
);

export { FAQ };
