import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { SetupIntent } from '@stripe/stripe-js';
import type { ComponentProps } from 'react';
import React, { forwardRef, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';

type PaymentElementProps = ComponentProps<typeof PaymentElement>;

export type CardRef = { submit: () => Promise<undefined | SetupIntent> };

// eslint-disable-next-line no-spaced-func
export default forwardRef<CardRef, { onChange: PaymentElementProps['onChange'] }>(({ onChange }, ref) => {
    const stripe = useStripe();
    const elements = useElements();

    function handleError(error: { message?: string }) {
        toast.error(error.message || '');
    }

    useImperativeHandle(ref, () => ({
        submit: async () => {
            if (!stripe || !elements) {
                // Stripe.js hasn't yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return undefined;
            }

            // Trigger form validation and wallet collection
            const { error: submitError } = await elements.submit();
            if (submitError) {
                handleError(submitError);
                return undefined;
            }

            // Use the clientSecret and Elements instance to confirm the setup
            const { error, setupIntent } = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: window.location.href.split('?')[0]
                },
                redirect: 'if_required'
            });

            if (error) {
                handleError(error);
            } else if (setupIntent.status === 'succeeded') {
                return setupIntent;
            } else {
                handleError({ message: 'Error confirming payment method' });
            }
            return undefined;
        }
    }), [elements, stripe]);

    return (
        <PaymentElement
            onChange={onChange}
            options={{
                fields: {
                    billingDetails: {
                        address: {
                            country: 'auto',
                            postalCode: 'auto'
                        }
                    }
                }
            }} />
    );
});
