import { observer } from 'mobx-react';
import { useRouter } from 'next/router';

import { Button } from '@/components/Button';
import NextImage from '@/components/image';
import SeoableText from '@/components/SeoableText';
import type { ComponentSectionsCallToAction } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import { useStore } from '@/stores/RootStore';

type ICallForActionProps = {
    data: ComponentSectionsCallToAction;
};

const CallForAction = observer((props: ICallForActionProps) => {
    const {
        layoutStore: { isMobile },
    } = useStore();
    const router = useRouter();
    return (
        <div className={'md:mx-16'}>
            <Section
                style={{ background: props.data.background }}
                padding={
                    'mt-36 mx-8 md:mx-auto px-8 pt-[3.5rem] pb-8 md:pb-16 md:px-16 md:py-16 md:w-full'
                }
            >
                <div
                    className={
                        'flex w-full flex-col justify-between text-black md:flex-row'
                    }
                >
                    <div
                        className={
                            'flex flex-col items-center justify-between text-center md:w-1/2 md:items-start md:pr-[3.7rem] md:text-left'
                        }
                    >
                        <SeoableText element="span" className={'text-lg leading-[2rem] md:text-2xl md:leading-[2.4rem]'}>{props.data.smallTitle}</SeoableText>
                        <SeoableText element="span"
                            className={
                                'mt-24 font-gravity text-6xl-m leading-hero-sm md:mt-0 md:pt-[2.3rem] md:text-[14rem] md:leading-[12rem]'
                            }
                            style={{ maxWidth: 649 }}
                            dangerouslySetInnerHTML={{ __html: props.data.bigTitle }}
                        />
                        <Button
                            className={'mt-16 md:mt-0'}
                            mobile={isMobile}
                            dark={props.data.action?.dark}
                            fullWidth={props.data.action?.fullWidth}
                            onClick={() => router.push(props.data?.action?.page?.data?.attributes?.slug!)}
                        >
                            {props.data.action?.text ?? ''}
                        </Button>
                    </div>

                    <div className={'relative mt-32 md:mt-0 md:w-[54rem]'}>
                        <NextImage
                            src={''}
                            priority
                            media={props.data.image}
                            alt={props.data.bigTitle}
                            width={isMobile ? 334 : 540}
                            height={isMobile ? 265 : 540}
                            layout="responsive"
                            objectFit="cover"
                        />
                    </div>
                </div>
            </Section>
        </div>
    );
});

export { CallForAction };
