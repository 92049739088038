import Link from 'next/link';

import { Button } from '@/components/Button';
import NextImage from '@/components/image';
import { Section } from '@/layouts/Section';
import type { IFeaturesScrollBlockProps } from '@/modules/FeaturesScrollSection/FeaturesScrollSection';

const FeaturesScrollSectionMobile = (props: IFeaturesScrollBlockProps) => (
    <Section
        padding={'pt-52 mt-5 relative p-8 w-full'}
        title={props.data.MainTitle ?? undefined}
    >
        <div
            className={
                'mt-[-3.5rem] flex-row items-center  justify-between text-black'
            }
        >
            {props.data.Features.map((item: any, index: number) => (
                <div
                    key={`Item-${index}`}
                    className={'item relative mb-8 p-[3rem]'}
                    style={{ background: item?.Background }}
                >
                    <span
                        className={'text-[3.2rem] leading-[3.2rem]'}
                        dangerouslySetInnerHTML={{ __html: item.Title }}
                    />
                    <>
                        <p
                            style={{ maxWidth: 273, lineHeight: '2.6rem' }}
                            className={'mb-[2rem] mt-[1rem] text-[2rem] leading-[2.6rem]'}
                        >
                            {item.Text}
                        </p>
                        <div className={'button'}>
                            {item?.ButtonDisabled ? (
                                <Button disabled mobile dark className='!text-[1.8rem]'>
                                    {item.ButtonText}
                                </Button>
                            ) : (
                                <Link href={item?.ButtonLink}>
                                    <a>
                                        <Button mobile dark className='!text-[1.8rem]'>
                                            {item?.ButtonText}
                                        </Button>
                                    </a>
                                </Link>
                            )}
                        </div>
                        <div className={'relative mt-[4.75rem]'}>
                            <NextImage
                                src=""
                                priority
                                media={item?.MobileImage.data ? item?.MobileImage : item?.Image}
                                alt={item?.Title}
                                width={318}
                                height={229}
                                layout="responsive"
                                objectFit='contain'
                            />
                        </div>
                    </>
                </div>
            ))}
        </div>
    </Section>
);

export { FeaturesScrollSectionMobile };
