import { motion } from 'framer-motion';
import { useRef, useState } from 'react';

import type { ICarouselHandle } from '@/components/Carousel/Carousel';
import { Carousel } from '@/components/Carousel/Carousel';
import { If } from '@/utils/If';

type IMobileHSliderProps = {
    items: any;
    accent?: string | null;
};

const MobileHorizontalSlider = (props: IMobileHSliderProps) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
    const carouselRef = useRef<ICarouselHandle>(null);
    return (
        <div className={'wrapper w-full md:mb-[7.4rem]'}>
            <If condition={props.items.length > 0}>
                <Carousel
                    initialWidth={95.5}
                    margin={8}
                    ref={carouselRef}
                    onChange={setActiveSlideIndex}
                >
                    {props.items}
                </Carousel>
                <div className={'navigation'}>
                    {props.items.map((_: any, i: number) => (
                        <motion.div
                            onClick={() => carouselRef.current?.slideTo(i)}
                            animate={
                                activeSlideIndex === i
                                    ? { backgroundColor: props.accent || '#FF2F00', width: 40 }
                                    : { width: 15 }
                            }
                            transition={{ ease: 'easeInOut', duration: 0.4 }}
                            key={i}
                        />
                    ))}
                </div>
            </If>
            <style jsx>
                {`

          .wrapper span {
            @apply text-base;
          }

          .wrapper p {
            @apply text-base opacity-70 leading-[2.2rem] mt-[0.8rem] max-w-[24.1rem] min-h-[11rem];
          }

          :global(.navigation) {
            @apply flex flex-row justify-center my-12 ml-[-2rem];
          }

          :global(.navigation div) {
            width: 1.5rem;
            height: 0.2rem;
            @apply bg-bpm-white bg-opacity-30 m-2;
          }
        `}
            </style>
        </div>
    );
};

export { MobileHorizontalSlider };
