/* eslint-disable @typescript-eslint/indent */
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import Link from 'next/link';
import { useState } from 'react';

import ActiveLink from '@/components/ActiveLink';
import { Button } from '@/components/Button';
import { MobileMenuIcon } from '@/components/MobileMenuIcon';
import type { NavigationItem } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import { MobileMenu } from '@/modules/MobileMenu/MobileMenu';
import { useStore } from '@/stores/RootStore';
import { Logo } from '@/templates/Logo';
import { redirectionTargetToAPIRedirect, redirectionTargetToUrl } from '@/utils/redirect';
import Responsive from '@/utils/Responsive';

type INavbarProps = {
    navigation: NavigationItem[];
    pageContext: {
        RedirectionTarget?: string
    };
};

const Navbar = observer((props: INavbarProps) => {
    const {
        layoutStore: { toggleMobileMenu, mobileMenuIsOpen },
        subscriptionStore: { isLoggedIn, logOut }
    } = useStore();

    const navClass = classNames('w-full', 'md:w-auto', 'md:block', {
        // hidden: !showMenu,
    });

    const subMenuAnimate = {
        enter: {
            opacity: 1,
            transition: {
                duration: 0.2
            },
            display: 'flex'
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.2
            },
            transitionEnd: {
                display: 'none'
            }
        }
    };

    const subMenuAnimateMobile = {
        enter: {
            opacity: 1,
            rotateY: 0,
            transition: {
                duration: 0.2
            },
            height: 'max-content'
        },
        exit: {
            opacity: 0,
            rotateY: -15,
            transition: {
                duration: 0.2
            },
            height: 0,
            transitionEnd: {
            }
        }
    };

    const [currentHover, setCurentHover] = useState<number>(-1);
    const hasRedirectionTarget = Boolean(props.pageContext.RedirectionTarget);
    const redirectQuery = hasRedirectionTarget ? { redirect: redirectionTargetToAPIRedirect(props.pageContext.RedirectionTarget), from: props.pageContext.RedirectionTarget?.toLowerCase() } : {};

    return (
        <Section fullWidth>
            <div
                className={classNames(
                    'flex h-[7.7rem] md:h-[6.6rem] border-b-[0.1rem] border-[#e6f0e933] justify-between items-center fixed left-0 top-0 right-0 px-[2rem] md:px-[4rem] md:py-[1.6rem] py-[2.2rem] backdrop-blur-[5rem] bg-[#000000E5] z-50',
                )}
            >
                <div className='flex flex-row'>
                    <div className="z-50 flex items-center">
                        <Link href="/">
                            <a>
                                <Responsive
                                    desktop={
                                        <div className={'logo-desk'}>
                                            <Logo width={113} height={24} showText={true} />
                                        </div>
                                    }
                                    mobile={
                                        <div
                                            className={'relative z-50 flex flex-row content-center'}
                                            style={{ maxHeight: 40 }}
                                        >
                                            <Logo width={26} height={32} showText={false} />
                                            <AnimatePresence initial={false} exitBeforeEnter={true}>
                                                {mobileMenuIsOpen && (
                                                    <motion.span
                                                        variants={{
                                                            hidden: { opacity: 0, x: -20 },
                                                            enter: { opacity: 1, x: 0 },
                                                            exit: { opacity: 0, x: 0 },
                                                        }} // Pass the variant object into Framer Motion
                                                        initial="hidden" // Set the initial state to variants.hidden
                                                        animate="enter" // Animated state to variants.enter
                                                        exit="exit" // Exit state (used later) to variants.exit
                                                        transition={{ duration: '0.5' }}
                                                        className={
                                                            'absolute top-[-0.4rem] left-12 ml-4 w-max text-[2.6rem] text-bpm-white'
                                                        }
                                                    >
                                                        bpm music
                                                    </motion.span>
                                                )}
                                            </AnimatePresence>
                                        </div>
                                    }
                                />
                            </a>
                        </Link>
                    </div>
                    <div className='ml-[2.4rem]'>
                        <nav className=" hidden md:block">
                            <ul className="navbar rounded-t">
                                {props.navigation.map(
                                    (menuItem: NavigationItem, index: number) => (
                                        <li className='linkWrap relative' key={`$II-${index}`} onMouseEnter={() => setCurentHover(index)} onMouseLeave={() => setCurentHover(-1)}>
                                            <ActiveLink
                                                notActive={menuItem.items === null}
                                                activeClassName="active"
                                                href={menuItem && menuItem.items && menuItem?.items?.length > 0 ? menuItem?.items[0]?.path : menuItem.path}
                                            >
                                                <a className={`mainLink mainLinkDesktop flex ${typeof window !== 'undefined' && menuItem?.items?.find((item) => item?.path === window.location.pathname) ? 'active' : ''}`}>{menuItem.title} {menuItem.items && menuItem.items?.length > 0 && <motion.img
                                                    className={`chevron ${currentHover === index ? 'rotate-90' : 'rotate-270'}`}
                                                    src={'/assets/icons/leftWhiteArrow.svg'}
                                                    style={{ transition: 'all 0.3s ease-in-out' }}
                                                    width={8}
                                                    height={4}
                                                />}</a>
                                            </ActiveLink>
                                            {menuItem.items && menuItem.items?.length > 0 && <motion.div
                                                initial="exit"
                                                animate={currentHover === index ? 'enter' : 'exit'}
                                                variants={subMenuAnimate}
                                                className="subMenuWrapper">{menuItem.items.map((subMenuItem) => <ActiveLink
                                                    activeClassName="active"
                                                    key={`Ta-d1-${index}-${subMenuItem?.title}`}
                                                    href={subMenuItem?.path}
                                                ><a>{subMenuItem?.title}</a></ActiveLink>)}</motion.div>}
                                        </li>
                                    )
                                )}
                            </ul>
                        </nav>
                    </div>
                </div>

                {/* <Responsive
                    desktop={
                        <div>
                            <nav className=" hidden md:block">
                                <ul className="navbar rounded-t">
                                    {props.navigation.map(
                                        (menuItem: NavigationItem, index: number) => (
                                            <li className='linkWrap relative' key={`$II-${index}`} onMouseEnter={() => setCurentHover(index)} onMouseLeave={() => setCurentHover(-1)}>
                                                <ActiveLink
                                                    notActive={menuItem.items === null}
                                                    activeClassName="active"
                                                    href={menuItem && menuItem.items && menuItem?.items?.length > 0 ? menuItem?.items[0]?.path : menuItem.path}
                                                >
                                                    <a className={`mainLink mainLinkDesktop flex ${typeof window !== 'undefined' && menuItem?.items?.find((item) => item?.path === window.location.pathname) ? 'active' : ''}`}>{menuItem.title} {menuItem.items && menuItem.items?.length > 0 && <motion.img
                                                        className={`chevron ${currentHover === index ? 'rotate-90' : 'rotate-270'}`}
                                                        src={'/assets/icons/leftWhiteArrow.svg'}
                                                        style={{ transition: 'all 0.3s ease-in-out' }}
                                                        width={8}
                                                        height={4}
                                                    />}</a>
                                                </ActiveLink>
                                                {menuItem.items && menuItem.items?.length > 0 && <motion.div
                                                    initial="exit"
                                                    animate={currentHover === index ? 'enter' : 'exit'}
                                                    variants={subMenuAnimate}
                                                    className="subMenuWrapper">{menuItem.items.map((subMenuItem) => <ActiveLink
                                                        activeClassName="active"
                                                        key={`Ta-d1-${index}-${subMenuItem?.title}`}
                                                        href={subMenuItem?.path}
                                                    ><a>{subMenuItem?.title}</a></ActiveLink>)}</motion.div>}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </nav>
                        </div>
                    }
                /> */}

                <Responsive
                    desktop={
                        <div className={`${navClass}`}>
                            <ul className="flex flex-row content-center items-center justify-between">
                                {!isLoggedIn ? <>
                                    <li className={'mx-14  text-[1.5rem]'}>
                                        <Link href={{ pathname: '/login', query: redirectQuery }}>
                                            <a className='block w-max cursor-pointer'>Login</a>
                                        </Link>
                                    </li>
                                    <li className={'min-w-[10.4rem] '}>
                                        <Link href={{ pathname: '/signup', query: redirectQuery }}>
                                            <a>
                                                <Button className='block !h-[3.8rem]  !w-[12.4rem] cursor-pointer !px-[1.6rem] !py-[1.2rem] !text-[1.4rem] !leading-[1.4rem]'>Try For Free</Button>
                                            </a>
                                        </Link>
                                    </li>
                                </> : <><li className={'mx-4 pt-[0.5rem] text-[1.5rem]'}>
                                    <a onClick={logOut} className='block w-max cursor-pointer'>{'Log Out'}</a>
                                </li>
                                    <li className={'mx-14 pt-[0.5rem] text-[1.4rem]'}>
                                        {!hasRedirectionTarget
                                            && <Link href={process.env.NEXT_PUBLIC_REDIRECT_GENERIC_URL ?? '/'}>
                                                <a><Button className='w-max !text-[1.4rem] !leading-[1.4rem]'>Enter BPM Music</Button></a>
                                            </Link>}
                                        {hasRedirectionTarget
                                            && <Link href={redirectionTargetToUrl(props.pageContext.RedirectionTarget)}>
                                                <a><Button className='w-max !text-[1.4rem] !leading-[1.4rem]'>{`Enter BPM ${(props.pageContext.RedirectionTarget as string)}`}</Button></a>
                                            </Link>}
                                    </li>
                                </>}
                            </ul>
                        </div>
                    }
                    mobile={
                        <div className={'flex flex-row '}>
                            <div className={'mr-6'}>
                                {!isLoggedIn ? <Link href={{ pathname: '/signup', query: redirectQuery }}>
                                    <a><Button className='!px-[1.6rem] !py-[1rem] !text-[1.4rem] !leading-[2.3rem]' mobile>Try For Free</Button></a>
                                </Link> : <>
                                    {!hasRedirectionTarget
                                        && <Link href={process.env.NEXT_PUBLIC_REDIRECT_GENERIC_URL ?? '/'}>
                                            <a><Button mobile>Enter BPM Music</Button></a>
                                        </Link>}
                                    {hasRedirectionTarget && <Link href={redirectionTargetToUrl(props.pageContext.RedirectionTarget)}>
                                        <a><Button mobile>{`Enter BPM ${(props.pageContext.RedirectionTarget as string)}`}</Button></a>
                                    </Link>}
                                </>}
                            </div>
                            <MobileMenuIcon onMenuOpenTapped={toggleMobileMenu} />
                        </div>
                    }
                />

                <Responsive
                    mobile={
                        <MobileMenu
                            list={
                                <nav className="">
                                    <ul className="navbar mobileNavbar rounded-t">
                                        {props.navigation.map(
                                            (menuItem: NavigationItem, index: number) => (
                                                <li className='linkWrap relative' key={`$II-${index}`} onClick={() => setCurentHover(currentHover === index ? -1 : index)}>
                                                    <ActiveLink
                                                        notActive={menuItem.items === null}
                                                        activeClassName="active"
                                                        dontLink={menuItem && menuItem.items && menuItem?.items?.length > 0}
                                                        href={menuItem && menuItem.items && menuItem?.items?.length > 0 ? menuItem?.items[0]?.path : menuItem.path}
                                                    >
                                                        <a className={`mainLink flex ${typeof window !== 'undefined' && menuItem?.items?.find((item) => item?.path === window.location.pathname && !item?.path.includes('blog.')) ? 'active' : ''}`}>{menuItem.title} {menuItem.items && menuItem.items?.length > 0 && <motion.img
                                                            className={`chevron ${currentHover === index ? 'rotate-90' : 'rotate-270'}`}
                                                            src={'/assets/icons/leftWhiteArrow.svg'}
                                                            style={{ transition: 'all 0.3s ease-in-out' }}
                                                            width={8}
                                                            height={4}
                                                        />}</a>
                                                    </ActiveLink>
                                                    <AnimatePresence presenceAffectsLayout>
                                                        {menuItem.items && menuItem.items?.length > 0 && <motion.div
                                                            initial="exit"
                                                            animate={currentHover === index ? 'enter' : 'exit'}
                                                            variants={subMenuAnimateMobile}
                                                            className="subMenuWrapper mobileSubMenuWrapper">{menuItem.items.map((subMenuItem) => <ActiveLink
                                                                activeClassName="active"
                                                                key={`Ta-d2-${index}-${subMenuItem?.title}`}
                                                                href={subMenuItem?.path}
                                                            ><a>{subMenuItem?.title}</a></ActiveLink>)}</motion.div>}
                                                    </AnimatePresence>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </nav>
                            }
                        />
                    }
                />

                <style jsx>
                    {`

                     :global(.subMenuWrapper) {
                        @apply pl-[1.5rem] pt-[5rem] pb-[1rem] pr-[0] absolute top-[-0.7rem] left-[-1.4rem] right-[-2.8rem] flex flex-col bg-black ;
                    }

                    :global(.mobileSubMenuWrapper) {
                        position: relative!important;
                        display:flex!important;
                        padding-top:1rem!important;
                        padding-bottom:0!important;
                    }

                    :global(.subMenuWrapper a) {
                        cursor: pointer;
                        @apply md:leading-[2.6rem] leading-[3.2rem] text-[2.8rem] md:text-[1.5rem] py-2
                    }

                    .mainLink {
                        @apply relative z-[1]
                    }

                    .linkWrap:hover .subMenuWrapper {
                        @apply opacity-100;
                    }

            .navbar {
              @apply flex flex-col font-medium text-xl text-gray-700 p-5 md:bg-white;
            }

            
            @media only screen and (min-width:770px) and (max-width:1513px) {
                .navbar :global(a) {
                    @apply text-[1.9rem];
                }
                .linkWrap {
                    margin-left: 2rem!important;
                    margin-right: 2rem!important;
                }
            }

            .navbar :global(a) {
              @apply w-full text-2xl-m md:text-[1.5rem] opacity-50;
            }

            :global(.chevron) {
                display: block;
                margin-top: 5px;
                margin-left: 11px;
            }

            .navbar :global(a.active) {
              @apply opacity-100;
            }

            .navbar :global(li) {
              @apply mx-[4rem];
            }
            .navbar :global(li:not(:first-child)) {
              @apply mt-3;
            }

            .navbar :global(a .btn) {
              @apply w-full;
            }

            .navbar :global(a:hover) {
              @apply text-primary-white opacity-100;
            }

            :global(.mobileNavbar li) {
                margin-left: 0!important;
                margin-right: 0!important;
            }

            @screen md {
              .navbar {
                @apply flex-row items-center p-0 bg-transparent;
              }

              /* Reset w-full rule */
              .navbar :global(a),
              .navbar :global(a .btn) {
                @apply w-auto;
              }

              .navbar :global(li:not(:first-child)) {
                @apply mt-0;
              }

              .navbar :global(li:not(:last-child)) {
              }
            }

            @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
              .navbar {
              }
            }

            
          `}
                </style>
            </div>
        </Section >
    );
});

export { Navbar };
