import { motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';

import type { ICarouselHandle } from '@/components/Carousel/Carousel';
import { Carousel } from '@/components/Carousel/Carousel';
import NextImage from '@/components/image';
import { Section } from '@/layouts/Section';
import { useStore } from '@/stores/RootStore';
import { If } from '@/utils/If';

import type { ILogoBoxesProps } from './LogoBoxes';

const LogoBoxesMobile = observer((props: ILogoBoxesProps) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
    const carouselRef = useRef<ICarouselHandle>(null);
    const {
        layoutStore: { accent },
    } = useStore();
    return (
        <Section
            titleClass={'ml-[-2rem] mb-[-3.4rem]'}
            padding={'pt-8 md:pt-36 mt-5 ml-8'}
            title={' A Complete Music Suite'}
            classNames={'mt-[16rem] md:mt-[0]'}
        >
            <div className={'wrapper grid '}>
                <If condition={props.data.Logos.length > 0}>
                    <Carousel
                        initialWidth={95.5}
                        margin={8}
                        ref={carouselRef}
                        onChange={setActiveSlideIndex}
                    >
                        {props.data.Logos.map((item, i: number) => (
                            <div key={`T-${i}`}>
                                <div className={'relative bg-[#151918]'} style={{ opacity: 1 }}>
                                    <NextImage
                                        src={''}
                                        priority
                                        media={item?.LogoImage}
                                        alt={item?.LogoName ?? ''}
                                        width={430}
                                        height={430}
                                        layout="responsive"
                                        objectFit="contain"
                                        objectPosition="middle"
                                    />
                                </div>
                                <span className='mt-[2rem] block text-[3.2rem] leading-[4rem]'>{item?.LogoName}</span>
                            </div>
                        ))}
                    </Carousel>
                    <div className={'navigation'}>
                        {props.data.Logos.map((_, i: number) => (
                            <motion.div
                                onClick={() => carouselRef.current?.slideTo(i)}
                                animate={
                                    activeSlideIndex === i
                                        ? { backgroundColor: accent || '#FF2F00', width: 40 }
                                        : { width: 15 }
                                }
                                transition={{ ease: 'easeInOut', duration: 0.4 }}
                                key={i}
                            />
                        ))}
                    </div>
                </If>
            </div>
            <style jsx>
                {`
          .wrapper {
            margin-bottom: 7.4rem;
          }

          :global(.wrapper span) {
            @apply text-base;
          }

          :global(.wrapper p) {
            @apply text-base opacity-70 leading-[2.2rem] mt-[0.8rem] max-w-[24.1rem] min-h-[11rem];
          }

          :global(.text) {
            @apply border-l-2 border-white border-opacity-20 pl-[1.7rem];
          }

          :global(.big) {
            grid-row: span 4;
          }

          :global(.big .text) {
            max-width: 39rem;
          }

          :global(.action) {
            @apply text-xl underline cursor-pointer;
          }

          :global(.navigation) {
            @apply flex flex-row justify-center my-12 ml-[-2rem];
          }

          :global(.navigation div) {
            width: 1.5rem;
            height: 0.2rem;
            @apply bg-bpm-white bg-opacity-30 m-2;
          }
        `}
            </style>
        </Section>
    );
});

export { LogoBoxesMobile };
