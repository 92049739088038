const isServerSide = (): boolean => typeof window === 'undefined';

export abstract class CookiesHelper {
    private constructor() {
        /* noop */
    }

    static getCookie(cookieName: string) {
        if (isServerSide()) {
            return false;
        }
        const cookieRegex = new RegExp(
            `(?:(?:^|.*;\\s*)${cookieName}\\s*=\\s*([^;]*).*$)|^.*$`
        );
        const cookieMatch = document.cookie.match(cookieRegex);
        return cookieMatch ? cookieMatch[1] : undefined;
    }

    static hasCookie(cookieName: string) {
        if (isServerSide()) {
            return false;
        }
        const cookieRegex = new RegExp(
            `(?:(?:^|.*;\\s*)${cookieName}\\s*=\\s*([^;]*).*$)|^.*$`
        );
        const cookieMatch = document.cookie.match(cookieRegex);
        return cookieMatch && !!cookieMatch[1];
    }

    static unsetCookie(name: string) {
        if (isServerSide()) {
            return;
        }
        const domain = '.therealest.com';
        const path = '/';
        document.cookie = `${name}=; expires=${+new Date()}; domain=${domain}; path=${path}`;
    }

    static setCookie(name: string, value: string) {
        if (isServerSide()) {
            return;
        }
        try {
            const domain = '.therealest.com';
            const path = '/';
            const date = new Date();
            date.setTime(date.getTime() + (365 * 5 * 24 * 60 * 60 * 1000));
            document.cookie = `${name}=${value}; expires=${date.toUTCString()}; domain=${domain}; path=${path}`;
        } catch (error) {
            /* noop */
        }
    }
}
