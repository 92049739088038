import Bugsnag from '@bugsnag/js';
import className from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { errorToString } from '@/api/error';
import type { LoginBody, UsersBody } from '@/api/supreme-api-sdk/src';
import type { ButtonRef } from '@/components/Button';
import { Button } from '@/components/Button';
import type { ComponentSectionsForgotPassword } from '@/generated/graphql-types';
import { userApiClient } from '@/pages/_app';
import { Logo } from '@/templates/Logo';

import styles from './ForgotPassword.module.scss';

type Inputs = {
    email?: string;
    password?: string;
};

type IForgotPasswordViewProps = {
    data: ComponentSectionsForgotPassword;
};

const variants = {
    hidden: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
};

const ForgotPassword = observer((props: IForgotPasswordViewProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();
    const router = useRouter();
    const buttonRef = useRef<ButtonRef>(null);

    const [token, setToken] = useState<string | null | string[]>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        router.query && router.query.token && setToken(router.query.token);
    }, [router.query]);

    const resetPassword = async (data: LoginBody) => {
        buttonRef?.current?.startLoading();
        try {
            await userApiClient.resetPassword({
                password: data.password,
                code: String(token)
            });
            buttonRef?.current?.stopLoadingWithSuccess();
            // toast.success('Password reset success!', {
            //     position: 'bottom-center',
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     progress: undefined,
            //     theme: 'dark',
            // });
            router.push('/login');
        } catch (error: any) {
            const errorString = await errorToString(error);
            Bugsnag.notify(Error(errorString), async (event: any) => {
                event.addMetadata('action', 'reset-password');
                event.addMetadata('formPayload', {
                    password: data.password,
                    code: String(token)
                });
            });
            buttonRef?.current?.stopLoading();
            toast.error('Token is invalid', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: 'dark',
            });
            setIsLoading(false);
        }
    };

    const requestReset = async (data: UsersBody) => {
        buttonRef?.current?.startLoading();
        try {
            await userApiClient.forgotPassword({ email: data.email });
            buttonRef?.current?.stopLoadingWithSuccess();
            // toast.success('We sent you an email, please check our mailbox', {
            //     position: 'bottom-center',
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     progress: undefined,
            //     theme: 'dark',
            // });
            router.push('/login');
            // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (error: any) {
            const errorString = await errorToString(error);
            Bugsnag.notify(Error(errorString), async (event: any) => {
                event.addMetadata('action', 'request-reset-password');
                event.addMetadata('formPayload', {
                    ...data
                });
            });
            buttonRef?.current?.stopLoading();
            toast.error('E-mail not found', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: 'dark',
            });
            setIsLoading(false);
        }
    };

    const onSubmit: SubmitHandler<Inputs> = async (data: any) => {
        if (isLoading) { return; }
        setIsLoading(true);
        token ? resetPassword(data) : requestReset(data);
    };

    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault();
                handleSubmit(onSubmit)();
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, []);

    return (
        <>

            <div
                className={
                    'header relative z-10 flex flex-row justify-between p-8 md:p-16'
                }
            >
                <Link href="/">
                    <a>
                        <Logo width={152.8} height={32} showText={true} />
                    </a>
                </Link>
                <img
                    onClick={() => router.push('/')}
                    className={'cursor-pointer hover:opacity-80'}
                    src={'/assets/icons/popUpClose.svg'}
                    alt='Close'
                    width={45}
                    height={45}
                />
            </div>
            <div
                className={
                    styles.main
                }
            >
                <div
                    className={className('pt-24 md:px-16', styles.formWrapper)}
                >
                    <form
                        autoComplete={'off'}
                        onSubmit={handleSubmit(onSubmit)}
                        className={className(
                            styles.mainFormClass,
                            'mt-5',
                        )}
                    >
                        <span
                            className={className('text-[4.5rem]', styles['leading-faqTitle'])}
                            dangerouslySetInnerHTML={{ __html: token ? props.data.ResetTitle! : props.data.Title! }}
                        />

                        <span
                            className={
                                'mt-12 block text-xl  leading-[2.6rem] text-bpm-white  opacity-50'
                            }
                        >
                            {token ? props.data.ResetText : props.data.SmallText}
                        </span>

                        <div className={'md:mt-10'}>

                            {!token && <div className={styles.group}>
                                <input
                                    autoComplete="off"
                                    className={styles.input}
                                    type={'text'}
                                    placeholder={' '}
                                    id={'email'}
                                    {...register('email', {
                                        required: true
                                    })}
                                />
                                <label className={styles.label} htmlFor="email">
                                    Email
                                </label>
                                <div className={styles.bar}></div>
                                <AnimatePresence>
                                    {errors.email && (
                                        <motion.span
                                            variants={variants}
                                            initial="hidden"
                                            transition={{ duration: '0.5' }}
                                            animate="enter"
                                            exit="exit"
                                            className={'mt-2 block text-bpm-supreme'}
                                        >
                                            This field is required and must be a valid Email
                                        </motion.span>
                                    )}
                                </AnimatePresence>
                            </div>}

                            {token && <div className={styles.group}>
                                <input
                                    autoComplete="off"
                                    className={styles.input}
                                    type={'password'}
                                    placeholder={' '}
                                    id={'password'}
                                    {...register('password', { required: true, minLength: 6 })}
                                />
                                <label className={styles.label} htmlFor="password">
                                    New Password
                                </label>
                                <div className={styles.bar}></div>
                                <AnimatePresence>
                                    {errors.password && (
                                        <motion.span
                                            variants={variants}
                                            initial="hidden"
                                            transition={{ duration: '0.5' }}
                                            animate="enter"
                                            exit="exit"
                                            className={'mt-2 block text-bpm-supreme'}
                                        >
                                            This field is required and length of at least 6 chars
                                        </motion.span>
                                    )}
                                </AnimatePresence>
                            </div>}
                        </div>

                        <Button
                            ref={buttonRef}
                            animated
                            className={'mt-8 w-full'}
                            onClick={() => handleSubmit(onSubmit)()}
                        >
                            Reset Password
                        </Button>

                    </form>

                    <div className={styles.imageWrapper}>
                        <img
                            className={styles.mainImage}
                            alt=''
                            src={token ? props.data.ResetImage?.data?.attributes?.url : props.data.Image?.data?.attributes?.url}
                        />
                    </div>
                    <div className={styles.shadow} />
                </div>
            </div>
        </>
    );
});

export { ForgotPassword };
