import {
    AnimatePresence,
    motion,
    useScroll
} from 'framer-motion';
import Link from 'next/link';
import { useRef, useState } from 'react';

import { Button } from '@/components/Button';
import NextImage from '@/components/image';
import SeoableText from '@/components/SeoableText';
import type { ComponentSectionsFeaturesScrollSection } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import useWindowDimensions from '@/utils/useWindowDimentions';

export type IFeaturesScrollBlockProps = {
    data: ComponentSectionsFeaturesScrollSection;
};

const FeaturesScrollSection = (props: IFeaturesScrollBlockProps) => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref, offset: ['100vh end', 'end 100vh'] });
    const [page, setPage] = useState<number>(0);
    const { width } = useWindowDimensions();

    const itemTitleVariants = {
        active: { opacity: 1, fontSize: width < 1513 ? '2.4rem' : '2.4rem' },
        not: { opacity: 0.5, fontSize: width < 1513 ? '2.4rem' : '2.4rem' },
    };

    const itemTitleVariantsTwo = {
        active: { opacity: 1, fontSize: '2.4rem', marginBottom: '1.5rem' },
        not: { opacity: 0.5, fontSize: '2.4rem' },
    };

    scrollYProgress.onChange((e) => {
        const currentPage = Math.floor(e * 3);
        if (currentPage > 2) {
            setPage(2);
        } else {
            setPage(currentPage);
        }
    });

    return (
        <div className=''>
            <Section
                style={{ margin: '0 auto' }}
                padding={'pt-[10.5rem] mt-5 relative'}
                classNames='max-w-standard'
            >
                <div
                    ref={ref}
                    style={{
                        height: 'calc((100vh*0.607) * 3)',

                    }}
                >
                    <div className='sticky top-[10vh]'>
                        {props.data.MainTitle && <SeoableText element="h3"
                            className={' block pb-[9.3rem] text-center text-3xl-m leading-sectionTitleMobile tracking-[-0.007rem] md:py-[4.6rem] md:text-5xl md:leading-[5.2rem] md:tracking-[-0.09rem]'}
                            dangerouslySetInnerHTML={{ __html: props.data.MainTitle }}
                        />}
                        <div
                            style={{
                                background:
                                    props.data.Features.length > 0
                                        ? props.data.Features[page]!.Background
                                        : '#FF2F00',
                                transition: 'all 0.3s ease-in-out',
                                // minHeight: '100vh',

                                // maxWidth: 'unset',
                                maxHeight: 556,
                                height: 974,

                                width: '100%',
                            }}
                            className={
                                ` flex min-h-[520px] w-screen min-w-[660px] max-w-full flex-row items-end justify-between overflow-hidden  ${props.data.Features[page]!.Background === '#000000' ? 'text-white' : 'text-black'}`
                            }
                        >
                            <div
                                className={
                                    `relative ml-[2rem] w-[256px] self-center border-l-2  ${props.data.ImageType === 'Normal' ? 'border-black' : 'ml-[14vw] border-white'} border-opacity-20 pl-[2.0rem]`
                                }
                            >
                                {props.data.InnerTitle && <h4 className='absolute left-[-0.5rem] top-[-12rem] mb-[6rem] text-[5.6rem]'>{props.data.InnerTitle}</h4>}
                                {props.data.Features.map((item: any, index: number) => (
                                    <div
                                        key={`Item-${index}`}
                                        className={`item relative ${index === props.data.Features.length - 1 ? 'mb-[0rem] zoomed:mb-[0rem]' : 'mb-[4rem] zoomed:mb-[4rem]'} ${index === page && 'active-item'
                                            }`}
                                    >
                                        {index === page && (
                                            <motion.div
                                                key={`Item-1-${index}`}
                                                layoutId="outline"
                                                className={'outline-line'}
                                                initial={false}
                                                style={{
                                                    bottom: index === props.data.Features.length - 1 ? '-2.5rem' : '0.7rem',
                                                    position: 'absolute',
                                                    left: '-2.2rem',
                                                    top: page !== 0 ? '1rem' : 0,
                                                    border: `1px solid ${props.data.ImageType === 'Normal' ? 'black' : props.data.SideLineColor}`,
                                                }}
                                                transition={{}}
                                            />
                                        )}

                                        <motion.div
                                            key={`Item-2-${index}`}
                                            initial={'not'}
                                            variants={props.data.ImageType === 'Normal' ? itemTitleVariants : itemTitleVariantsTwo}
                                            animate={index === page ? 'active' : 'not'}
                                            transition={{
                                                duration: 0.3,
                                            }}
                                        >
                                            <SeoableText element="span" className={'block tracking-[-0.08rem]'} dangerouslySetInnerHTML={{ __html: item.Title }}></SeoableText>
                                        </motion.div>

                                        <motion.div
                                            key={`Item-3-${index}`}
                                            style={{ height: index === page ? index === props.data.Features.length - 1 ? '11.5rem' : '14rem' : 0 }}
                                        >
                                            <AnimatePresence
                                                presenceAffectsLayout={true}
                                                initial={false}
                                                exitBeforeEnter={true}
                                                key={`Item-4-${index}`}
                                            >
                                                {index === page && (
                                                    <motion.div
                                                        initial={{ height: 0 }}
                                                        animate={{ height: 'fit-content' }}
                                                        exit={{ opacity: 0 }}
                                                        transition={{ duration: 0.6 }}
                                                        key={`Item-5-${index}`}>
                                                        <motion.div
                                                            key={`Item-3-${index}`}
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: props.data.ImageType === 'Normal' ? 1 : 0.5 }}
                                                            exit={{ opacity: 0 }}
                                                            style={{ maxWidth: props.data.ImageType === 'Normal' ? 259 : 400 }}
                                                            transition={{
                                                                duration: 1,
                                                            }}
                                                        >
                                                            <SeoableText element="p" className={'mb-16 h-[6rem] text-[1.7rem] leading-[2.4rem] opacity-50  md:mb-[4rem]'}>{item.Text}</SeoableText>
                                                        </motion.div>
                                                        <motion.div
                                                            key={`Item-4-${index}`}
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1 }}
                                                            exit={{ opacity: 0 }}
                                                            transition={{
                                                                duration: 0.3,
                                                            }}
                                                            className={'button'}
                                                        >
                                                            {item.ButtonDisabled ? (
                                                                <Button disabled dark={props.data.ImageType === 'Normal'}>
                                                                    {item.ButtonText}
                                                                </Button>
                                                            ) : (
                                                                <Link href={item.ButtonLink}>
                                                                    <a><Button dark={props.data.ImageType === 'Normal'}>{item.ButtonText}</Button></a>
                                                                </Link>
                                                            )}
                                                        </motion.div>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </motion.div>
                                    </div>
                                ))}
                            </div>
                            <div
                                className={`relative ${props.data.ImageType === 'Normal' ? '' : ''} h-full w-full max-w-[71%]`}
                            >
                                <AnimatePresence
                                    presenceAffectsLayout={false}
                                    initial={true}
                                    exitBeforeEnter={false}
                                >
                                    {props.data.Features.map((item: any, index: number) => page === index && (
                                        <motion.img
                                            initial={{ opacity: 0, y: 0, x: 0 }}
                                            animate={{ opacity: 1, y: 0, x: 0 }}
                                            exit={{ opacity: 0, y: 0, x: 0 }}
                                            transition={{ duration: 0.5 }}
                                            key={`$ImWrap-${index}`}
                                            src={item.Image.data.attributes.formats.medium.url}
                                            className={`absolute ${props.data.ImageType !== 'Normal' ? 'inset-0 m-auto h-fit' : 'inset-y-[2rem] left-[2rem]'}  w-[700px] max-w-none`}
                                        />
                                    ))}
                                </AnimatePresence>
                            </div>
                        </div>
                    </div>
                    <style jsx>
                        {`
            .outline-line {
              position: absolute;
              top: -20px;
              left: -20px;
              right: -20px;
              bottom: -20px;
              border: 10px solid white;
              border-radius: 50%;
            }

            .item p {
              max-width: 238px;
            }

            .item p,
            .item .button {
              @apply opacity-0;
            }

            .active-item p,
            .active-item .button {
              @apply opacity-100;
            }
          `}
                    </style>
                </div>
                {
                    props.data.Features.map((item: any, index: number) => page === index && (
                        <div key={`$ImWrap2-${index}`} className={''}>
                            <NextImage
                                src={''}
                                priority
                                media={item.Image}
                                alt={item.Title}
                                width={1}
                                height={1}
                                layout="fixed"
                            />
                        </div>
                    ))
                }
            </Section >
        </div >
    );
};
export { FeaturesScrollSection };
