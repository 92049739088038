/* eslint-disable max-len */
import type { MotionStyle, MotionValue } from 'framer-motion';
import { motion, useScroll, useTransform } from 'framer-motion';
import { observer } from 'mobx-react';
import type { RefObject } from 'react';
import React, { useEffect, useState } from 'react';

// import JsxParser from 'react-jsx-parser';
import NextImage from '@/components/image';
import type { ComponentElementsParallexCover, ComponentSectionsImageWithParallax, Maybe } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import { useStore } from '@/stores/RootStore';

type IImageWIthParallaxProps = {
    data: ComponentSectionsImageWithParallax;
};

function useParallax(value: MotionValue<number>, distance: number) {
    return useTransform(value, [0, 1], [-distance, distance]);
}
function Cover({
    coverItem, parentRef, index, isMobile
}: { coverItem: Maybe<ComponentElementsParallexCover>, parentRef: RefObject<HTMLElement> | undefined, index: number, isMobile: boolean }) {
    const ref = React.useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref, container: parentRef, offset: ['-100vh end', 'start -600px']
    });
    const [style, setStyle] = useState<MotionStyle>({});
    const y = useParallax(scrollYProgress, -600);
    useEffect(() => {
        switch (Number(index)) {
            case 0:
                setStyle(isMobile ? { right: '-30vw', top: '25vh' } : { left: 0 });
                break;
            case 1:
                setStyle(isMobile ? { left: '-25vw', top: '50vh' } : { left: '18vw', top: '85%' });
                break;
            case 2:
                setStyle(isMobile ? { right: 0, top: '85vh' } : { right: '25vw', top: '44%' });
                break;
            case 3:
                setStyle({ right: 0, top: '100%' });
                break;
            default:
                break;
        }
    }, [isMobile]);
    return (
        <section>
            <div ref={ref}></div>
            <motion.div style={{
                y,
                position: 'absolute',
                zIndex: -1,
                ...style

            }} className={isMobile ? 'w-[53vw] max-w-[22rem]' : 'w-[22.4vw] max-w-[43rem]'} key={Math.random()}>
                <NextImage
                    src={''}
                    priority
                    media={coverItem?.Cover}
                    width={430}
                    height={430}
                    layout="responsive"
                    objectFit='contain'
                />
            </motion.div>
        </section>
    );
}

// const TextLoop = observer(({
//     words, color, onWordChanged
// }: { words: string[]; color: string; onWordChanged: (word: string) => void; }) => {
//     const {
//         layoutStore: { isMobile },
//     } = useStore();
//     const [index, setIndex] = useState(0);

//     useEffect(() => {
//         setTimeout(() => {
//             let next = index + 1;
//             if (next === words.length) {
//                 next = 0;
//             }
//             setIndex(next);
//             onWordChanged(words[index]!);
//         }, 3 * 1000);
//     }, [index, setIndex]);

//     return (
//         <div className='absolute'>
//             <AnimatePresence>
//                 <motion.span
//                     style={{
//                         position: 'absolute', top: isMobile ? 20 : -28, fontSize: isMobile ? 40 : 80, lineHeight: isMobile ? '40px' : '88px', color
//                     }}
//                     variants={{
//                         enter: (_direction) => ({
//                             y: 0,
//                             opacity: 0
//                         }),
//                         center: {
//                             zIndex: 1,
//                             y: -60,
//                             opacity: 1
//                         },
//                         exit: (_direction) => ({
//                             zIndex: 0,
//                             y: -120,
//                             opacity: 0
//                         })
//                     }}
//                     key={index}
//                     initial="enter"
//                     animate="center"
//                     exit="exit"
//                     transition={{

//                         opacity: { duration: 0.2 }
//                     }}
//                 >
//                     {words[index]}
//                 </motion.span>
//             </AnimatePresence>
//         </div>
//     );
// });

// const AnimatedText = (props: any) => {
//     const [currentWord, setCurrentWord] = useState<string>(props.children.props.children.split(',')[0]);
//     const words = props.children.props.children.split(',');

//     return <div className='relative inline-block'>
//         <span className='opacity-0'>{currentWord}</span><TextLoop onWordChanged={(word: string) => setCurrentWord(word)} color={props.color} words={words} />
//     </div>;
// };

// const JsxParserAny: any = JsxParser;
const ImageWIthParallax = observer((props: IImageWIthParallaxProps) => {
    const {
        layoutStore: { isMobile },
    } = useStore();
    const ref = React.useRef(null);
    const getImage = React.useCallback(() => (isMobile && props.data.MainImageMobile && props.data.MainImageMobile.data ? props.data.MainImageMobile : props.data.MainImage), [isMobile, props.data.MainImageMobile, props.data.MainImage]);
    const image = getImage();

    return (
        <div ref={ref} className={'overflow-x-clip pb-[0rem] md:pb-0'}>
            {/* The newest<br/>Latin <animatedText color="#DFFD12">music,music2,music3</animatedText> */}

            <Section
                // customTitle={<div className='flex justify-center text-center'>
                //     <SeoableText element="h2" className='relative z-[1] md:pb-[19.2rem]' style={{ fontSize: isMobile ? 40 : 80, lineHeight: isMobile ? '40px' : '88px', margin: '0 auto' }} dangerouslySetInnerHTML={{ __html: props.data.SectionTitle! }} />
                //     {/* {props.data.SectionTitle && props.data.SectionTitle.includes('AnimatedText') ? <JsxParserAny
                //             bindings={{
                //                 isMobile,
                //             }}
                //             components={{ AnimatedText }}
                //             jsx={props.data.SectionTitle as any}
                //         /> : <span dangerouslySetInnerHTML={{ __html: props.data.SectionTitle! }} />} */}
                //     {/* </h2> */}
                // </div>}
                titleClass='md:text-[5.6rem] text-[4rem] leading-[4rem] md:leading-[5.6rem] pb-[2rem]'
                title={props.data.SectionTitle && !props.data.SectionTitle.includes('AnimatedText') ? props.data.SectionTitle : undefined}
                padding={
                    'mt-36 mx-8 md:mx-auto px-8 pt-[3.5rem] mb-[4rem]  md:px-16 md:py-16 md:w-full relative max-w-[101.4rem]'
                }
                smallTitle={props.data.MiniTitle}
            >

                {props.data.Covers.filter((cover) => cover && cover.Cover && cover.Cover.data).slice(0, !isMobile ? props.data.Covers.length : 3).map((cover, index: number) => <Cover isMobile={isMobile} index={index} parentRef={ref} coverItem={cover} key={cover?.id} />)}
                <div
                    className={
                        'flex w-full flex-col justify-center text-black md:mt-[10rem] md:flex-row'
                    }
                >
                    {image && image.data
                        && <div className={`${image.data?.attributes?.width! > image.data?.attributes?.height! ? 'w-full pb-[20rem] md:w-[63vw] md:pb-[0rem]' : 'w-full md:w-[26vw]'} relative mx-auto mt-[6rem]  max-w-[102rem] md:mt-[0] `}>
                            <NextImage
                                src={''}
                                priority
                                media={image}
                                alt={props.data.SectionTitle ?? ''}
                                width={image.data!.attributes?.width!}
                                height={image.data!.attributes?.height!}
                                layout="responsive"
                                objectFit='contain'
                                objectPosition='top'
                            />
                        </div>
                    }
                </div>
            </Section >
        </div >
    );
});

export { ImageWIthParallax };
