import { useRouter } from 'next/router';

type ILogoProps = {
    width: number;
    height: number;
    showText?: boolean;
    disableHomeOnClick?: boolean;
};

const Logo = (props: ILogoProps) => {
    const router = useRouter();
    return (
        <svg
            onClick={() => !props.disableHomeOnClick && router.push('/')}
            preserveAspectRatio="xMinYMid slice"
            width={props.width}
            height={props.height}
            viewBox={'0 0 187 39.58'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_203_10990)">
                <path
                    d="M28.0988 19.7883C30.5285 17.6789 32.118 14.6038 32.118 11.3031C32.118 5.13701 27.1435 0.110763 20.9918 0.00390625H0V5.64359H20.7936C23.921 5.64359 26.4657 8.18441 26.4657 11.307C26.4657 14.4296 23.4691 16.9704 20.7936 16.9704V22.6141C23.4731 22.6141 26.4657 25.0362 26.4657 28.2775C26.4657 31.5189 23.921 33.9409 20.7936 33.9409H0V39.5846H20.9918C27.1435 39.4738 32.118 34.4475 32.118 28.2815C32.118 24.9808 30.5285 21.9057 28.0988 19.7962"
                    fill="#E6F0E9"
                />
                <path d="M20.7936 22.6094H0V28.2886H20.7936V22.6094Z" fill="#E6F0E9" />
                <path d="M20.7936 11.2871H0V16.9664H20.7936V11.2871Z" fill="#E6F0E9" />

                {props.showText && (
                    <>
                        <path
                            d="M41.7383 7.33789H46.2292V15.9181C47.2558 14.8614 48.6668 14.1253 50.5892 14.1253C57.066 14.1253 57.8983 20.1133 57.8983 22.2583C57.8983 24.4034 57.066 30.328 50.5575 30.328C49.0196 30.328 46.8396 29.4969 45.9715 28.2146V29.9441H41.7383V7.33789ZM53.4074 22.2544C53.4074 19.5988 52.1272 17.4854 49.7212 17.4854C48.437 17.4854 46.1935 18.5421 46.1935 22.2544C46.1935 25.9667 48.437 26.96 49.7212 26.96C52.1272 26.96 53.4074 24.91 53.4074 22.2544Z"
                            fill="#E6F0E9"
                        />
                        <path
                            d="M59.6953 14.5089H63.9286V16.2384C64.7926 14.9561 66.941 14.125 68.5463 14.125C75.023 14.125 76.0813 20.0813 76.0813 22.1907C76.0813 24.3002 75.023 30.3238 68.5146 30.3238C66.5922 30.3238 65.2128 29.5876 64.1862 28.5309V37.0795H59.6953V14.5089ZM71.5904 22.2264C71.5904 21.043 71.0117 17.489 67.7734 17.489C66.394 17.489 64.1505 18.5141 64.1505 22.1947C64.1505 25.8753 66.394 26.9637 67.6782 26.9637C71.0117 26.9637 71.5904 23.4097 71.5904 22.2264Z"
                            fill="#E6F0E9"
                        />
                        <path
                            d="M77.8751 14.5089H82.2351V16.365C82.4928 15.7556 83.3886 14.125 86.405 14.125C87.6852 14.125 88.7792 14.3506 89.8336 15.3717C90.1229 15.6289 90.4123 15.9811 90.6977 16.3967C91.4349 14.9561 92.7509 14.125 94.8992 14.125C97.0475 14.125 98.522 14.9561 99.3901 16.1078C100.254 17.2595 100.575 18.7001 100.575 20.0773V29.9359H96.0844V20.0417C96.0844 19.3689 95.795 17.4811 94.0946 17.4811C93.3573 17.4811 92.5249 17.96 92.1088 18.6328C91.6291 19.3689 91.4666 20.299 91.4666 21.2884V29.9319H87.0114V20.0378C87.0114 19.365 86.7221 17.4771 85.0573 17.4771C84.2883 17.4771 83.456 17.956 82.9724 18.6288C82.4928 19.365 82.362 20.295 82.362 21.32V29.9319H77.8711V14.5089H77.8751Z"
                            fill="#E6F0E9"
                        />
                        <path
                            d="M108.109 14.4366H112.493V16.3047C112.751 15.6596 113.682 14.0527 116.679 14.0527C117.971 14.0527 119.061 14.2783 120.123 15.3073C120.444 15.5962 120.702 15.9168 120.995 16.3363C121.705 14.8878 123.088 14.0527 125.217 14.0527C127.345 14.0527 128.855 14.8878 129.723 16.0434C130.56 17.203 130.917 18.6832 130.917 20.0328V29.9388H126.406V20.0011C126.406 19.2927 126.117 17.4286 124.412 17.4286C123.703 17.4286 122.835 17.9115 122.414 18.5843C121.963 19.2927 121.772 20.2544 121.772 21.2517V29.9349H117.297V19.9972C117.297 19.2887 117.008 17.4247 115.331 17.4247C114.558 17.4247 113.69 17.9075 113.238 18.5803C112.787 19.2887 112.628 20.2505 112.628 21.2794V29.9309H108.117V14.4366H108.109Z"
                            fill="#E6F0E9"
                        />
                        <path
                            d="M132.957 14.4376H137.464V24.3119C137.464 24.6642 137.559 25.3093 137.884 25.8871C138.205 26.4333 138.788 26.9478 139.85 26.9478C140.334 26.9478 141.138 26.8211 141.816 26.271C142.525 25.7248 143.041 24.7592 143.041 23.0851V14.4336H147.547V29.9358H143.168V28.0717C142.878 28.7129 141.622 30.3197 138.498 30.3197C136.148 30.3197 134.697 29.259 133.928 28.0045C133.155 26.7182 132.961 25.238 132.961 24.3396V14.4336L132.957 14.4376Z"
                            fill="#E6F0E9"
                        />
                        <path
                            d="M153.266 24.2794C153.65 26.1157 154.455 27.1091 156.262 27.1091C157.903 27.1091 159.128 26.468 159.128 25.3717C159.128 24.2754 158.387 23.9904 156.358 23.6659L155.517 23.5393C152.009 22.9931 149.397 22.0274 149.397 18.6516C149.397 17.0764 151.038 14.0527 156.58 14.0527C159.445 14.0527 162.311 15.6279 163.147 18.6199L159.251 19.6172C158.799 17.9431 158.094 17.2703 156.318 17.2703C154.935 17.2703 153.71 17.6898 153.71 18.6199C153.71 20.0684 155.834 20.1317 156.96 20.3256L157.796 20.4523C160.341 20.9035 163.659 21.7069 163.659 25.0194C163.659 28.332 160.5 30.3227 156.251 30.3227C152.513 30.3227 149.615 28.3914 148.938 25.2411L153.254 24.2754L153.266 24.2794Z"
                            fill="#E6F0E9"
                        />
                        <path
                            d="M165.219 7.23438H169.729V11.8649H165.219V7.23438ZM165.219 14.4373H169.729V29.9395H165.219V14.4373Z"
                            fill="#E6F0E9"
                        />
                        <path
                            d="M186.997 24.2794C186.676 26.5986 184.258 30.3267 179.3 30.3267C174.341 30.3267 171.281 26.278 171.281 22.1897C171.281 18.1014 174.147 14.0527 179.3 14.0527C184.036 14.0527 186.419 17.4286 186.934 19.7122L182.427 20.6462C182.138 19.356 181.492 17.4326 179.3 17.4326C176.755 17.4326 175.792 19.9734 175.792 22.1897C175.792 24.406 176.755 26.9508 179.3 26.9508C181.845 26.9508 182.265 24.7305 182.491 23.4126L187.001 24.2794H186.997Z"
                            fill="#E6F0E9"
                        />
                    </>
                )}
            </g>
            <defs>
                <clipPath id="clip0_203_10990">
                    <rect width="187" height="39.58" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export { Logo };
