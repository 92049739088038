function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function errorToString(err: any, defaultMessage = 'An unhandled error occurred. Please try again later.'): Promise<string> {
    if (err instanceof Response) {
        return err.json().then(({ error }) => errorToString(error)).catch(() => defaultMessage);
    }
    if (err && err.type === 'InvalidParametersError') {
        if ((err as any).missing_keys && (err as any).missing_keys.length) {
            return (err as any).missing_keys.map((k: string) => capitalizeFirstLetter(`${k} is missing`)).join(', ');
        }
        return Object.keys((err as any).invalid_keys).map((key) => capitalizeFirstLetter(`${key}: ${(err as any).invalid_keys[key]}`)).join(', ');
    } if ((err && err.type === 'ResourceAlreadyExists') || (err && err.message)) {
        return capitalizeFirstLetter(err.message);
    }
    return defaultMessage;
}
