import { useRouter } from 'next/router';

export function getQueryVariable(variable: string) {
    const query = window.location.search.substring(1);
    if (query === '') {
        return undefined;
    }
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i += 1) {
        const pair = vars[i]?.split('=');
        if (decodeURIComponent(pair![0] || '') === variable) {
            return decodeURIComponent(pair![1] || '');
        }
    }
    return undefined;
}

export function useUrlQuery() {
    const router = useRouter();
    const { query } = router;
    delete query.slug;
    return { ...query };
}
