import Link from 'next/link';

import type { NavigationItem } from '@/generated/graphql-types';

import { Section } from '../layouts/Section';
import { FooterCopyright } from '../modules/footer/FooterCopyright';
import { FooterLinks } from '../modules/footer/FooterLinks';
import { Logo } from './Logo';

export type IFooterProps = {
    navigation: NavigationItem[];
    copyrights: string;
};

const Footer = (props: IFooterProps) => (
    <div className='md:mx-16'>
        <Section background={'bg-black md:px-[0rem] py-[4rem]'}>
            <div className="mx-auto flex max-w-7xl flex-row justify-between">
                <div className={'mr-[2.9rem]'}>
                    <Link href="/">
                        <a>
                            <Logo width={33} height={40} />
                        </a>
                    </Link>
                </div>
                <div className="flex w-full flex-col justify-around text-[1.6rem] leading-[1.8rem] text-bpm-white sm:flex-row">
                    {props.navigation.map((footerItem, index) => (
                        <FooterLinks key={index} title={footerItem.title}>
                            {footerItem.items?.map((link: any, index2) => (
                                <li key={index2}>
                                    <Link href={link?.path}>
                                        <a>{link?.title}</a>
                                    </Link>
                                </li>
                            ))}
                        </FooterLinks>
                    ))}
                </div>
            </div>
            <FooterCopyright copyrights={props.copyrights} />
        </Section>
    </div>
);

export { Footer };
