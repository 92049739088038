import type { MotionStyle, MotionValue } from 'framer-motion';
import { motion, useScroll, useTransform } from 'framer-motion';
import { observer } from 'mobx-react';
import Link from 'next/link';
import type { RefObject } from 'react';
import React, { useEffect, useState } from 'react';

import { Button } from '@/components/Button';
import NextImage from '@/components/image';
import SeoableText from '@/components/SeoableText';
import type { ComponentElementsParallexCover, ComponentSectionsTextAndButtonWithParallax, Maybe } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import { useStore } from '@/stores/RootStore';

type ITextWIthParallaxProps = {
    data: ComponentSectionsTextAndButtonWithParallax;
};

function useParallax(value: MotionValue<number>, distance: number) {
    return useTransform(value, [0, 1], [-distance, distance]);
}
function Cover({
    coverItem, parentRef, index, isMobile
}: { coverItem: Maybe<ComponentElementsParallexCover>, parentRef: RefObject<HTMLElement> | undefined, index: number, isMobile: boolean }) {
    const ref = React.useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref, container: parentRef, offset: ['-100vh end', 'start -600px']
    });
    const [style, setStyle] = useState<MotionStyle>({});
    const y = useParallax(scrollYProgress, -600);
    useEffect(() => {
        switch (Number(index)) {
            case 0:
                setStyle(isMobile ? { left: '-22vw' } : { left: '-12vw' });
                break;
            case 1:
                setStyle(isMobile ? { right: '-35vw', top: '60vh' } : { left: '5vw', top: '105%' });
                break;
            case 2:
                setStyle(isMobile ? { left: '-12vw', top: '100vh' } : { right: '-12vw', top: '44%' });
                break;
            default:
                break;
        }
    }, [isMobile]);

    return (
        <section>
            <div ref={ref}></div>
            <motion.div style={{
                y,
                position: 'absolute',
                zIndex: -1,
                ...style

            }} className={isMobile ? 'w-[53vw] max-w-[22rem]' : 'w-[22.4vw] max-w-[43rem]'} key={Math.random()}>
                <NextImage
                    src={''}
                    priority
                    media={coverItem?.Cover}
                    width={430}
                    height={430}
                    layout="responsive"
                    objectFit='contain'
                />
            </motion.div>
        </section>
    );
}

const TextWIthParallax = observer((props: ITextWIthParallaxProps) => {
    const ref = React.useRef(null);
    const {
        layoutStore: { isMobile },
    } = useStore();
    return (
        <div ref={ref} className={'mb-[30rem] overflow-x-clip md:mb-[0rem] md:pt-[30rem]'}>
            <Section
                padding={
                    'mx-8 md:mx-auto px-8 pt-[3.5rem]  md:px-16 md:py-16 md:w-full relative'
                }
            >
                {props.data.Covers.map((cover, index: number) => <Cover index={index} isMobile={isMobile} parentRef={ref} coverItem={cover} key={cover?.id} />)}
                <div
                    className={
                        'flex w-full flex-col justify-center text-black md:flex-row'
                    }
                >
                    <div className={'relative flex h-[50vh] w-full max-w-[111.8rem] flex-col justify-center'}>
                        <SeoableText element="h4" className='mx-auto mb-[6.2rem] max-w-[90rem] text-center text-[4rem] leading-[4rem] text-white md:text-[8rem] md:leading-[8rem]' dangerouslySetInnerHTML={{ __html: props.data.Text }} />
                        <div className='flex justify-center'>
                            <Link href={props.data.Button?.page?.data?.attributes?.slug!}>
                                <a>
                                    <Button>
                                        {props.data.Button?.text || ''}
                                    </Button>
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </Section>
        </div>
    );
});

export { TextWIthParallax };
