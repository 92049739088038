import classNames from 'classnames';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';

import { Button } from '@/components/Button';
import NextImage from '@/components/image';
import SeoableText from '@/components/SeoableText';
import type { ComponentSectionsLibrariesSection } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import { MobileHorizontalSlider } from '@/modules/MobileHorizontalSlider/MobileHorizontalSlider';
import Responsive from '@/utils/Responsive';

type ISupremeLibrariesBlocksProps = {
    data: ComponentSectionsLibrariesSection;
};

const SupremeLibrariesBlocks = observer(
    (props: ISupremeLibrariesBlocksProps) => {
        const router = useRouter();
        return (
            <Section
                title={props.data.Title}
                titleClass='text-[3.2rem] md:!pb-[1.5rem] !pb-[3rem] md:text-[4.8rem]'
                padding={
                    'ml-8 md:ml-unset md:mx-auto mt-[13.5rem]  md:w-full w-[95vw]  max-w-standard'
                }
            // 'ml-8 md:ml-unset md:mx-auto mt-[14rem] md:mt-[22rem] md:w-full w-[95vw] md:p-16 max-w-standard'
            >
                <div
                    className={
                        'flex w-full flex-row flex-wrap justify-between md:-mx-2 md:mt-8'
                    }
                >
                    <Responsive
                        mobile={
                            <MobileHorizontalSlider
                                items={props.data.Items.map((item: any, i: number) => (
                                    <div
                                        key={`Item-${i}`}
                                        className={'relative mb-4 text-black'}
                                    >
                                        <div className={'relative'}>
                                            <NextImage
                                                src=""
                                                priority
                                                media={item.Image}
                                                alt={item.Title}
                                                width={374}
                                                height={500}
                                                layout="responsive"
                                                objectFit="cover"
                                            />
                                        </div>
                                        <div
                                            className={classNames(
                                                'flex flex-col md:flex-row justify-between p-8 absolute right-8 bottom-8 left-8 md:left-none md:right-16 md:bottom-16 h-[18rem] md:h-[21.4rem]'
                                            )}
                                            style={{ background: item.Background }}
                                        >
                                            <div className={''}>
                                                <span
                                                    className={
                                                        'block text-[1.8rem] leading-[2rem] md:w-[7.7rem] md:text-2xl md:leading-[2.8rem]'
                                                    }
                                                >
                                                    {item.Title}
                                                </span>
                                            </div>
                                            <div
                                                className={
                                                    'flex flex-col border-black md:w-[26.8rem] md:border-l md:pl-8'
                                                }
                                            >
                                                <span className={'mt-4 text-[1.8rem] leading-[2rem] md:text-2xl md:leading-heroDesc'}>
                                                    {item.Text}
                                                </span>
                                                <Button
                                                    className={'mt-[2rem] w-fit-content !leading-[2.2rem] md:mt-16 md:!leading-[1.6rem]'}
                                                    fullWidth={false}
                                                    dark
                                                    onClick={() => router.push(item.ButtonLink)}
                                                >
                                                    {item.ButtonTitle}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            />
                        }
                        desktop={
                            <>
                                {props.data.Items.map((item: any, i: number) => (
                                    <div
                                        key={`Item-${i}`}
                                        className={`relative mx-2 mb-4 ${!item.IsFullWidth ? 'w-[48.2%]' : 'max-h-[64.2rem] w-[100%] md:mb-[4rem]'} text-black `}
                                    >
                                        <div className={'relative'}>
                                            <NextImage
                                                priority
                                                src=""
                                                media={item.Image}
                                                alt={item.Title}
                                                width={item.IsFullWidth ? 1040 : 510}
                                                height={item.IsFullWidth ? 510 : 610}
                                                layout="responsive"
                                                objectFit="cover"
                                            />
                                        </div>
                                        <motion.div
                                            whileInView={{
                                                opacity: 1, y: 0
                                            }}
                                            style={{ opacity: 0, y: 20, background: item.Background }}
                                            transition={{ ease: 'easeInOut', duration: 0.8, delay: 0 }}
                                            className={classNames(
                                                item.IsFullWidth ? 'flex flex-col justify-between p-8 absolute bottom-[2rem] top-[2rem] left-[2rem] h-[46.7rem] w-[30.8rem]' : 'flex justify-between p-8 absolute right-[2rem] bottom-[2rem] h-[18rem] md:h-[21.4rem] w-[46.2rem] twoTwenty:w-[92%] '
                                            )}
                                        >
                                            <div className={''}>
                                                <SeoableText element="span"
                                                    className={
                                                        `block ${!item.IsFullWidth && 'w-[7.7rem]'} text-2xl leading-[3rem]`
                                                    }
                                                >
                                                    {item.Title}
                                                </SeoableText>
                                            </div>
                                            <div
                                                className={
                                                    `flex w-[26.8rem] flex-col  ${!item.IsFullWidth && 'border-l border-black pl-8'}`
                                                }
                                            >
                                                <SeoableText element="span" className={'text-2xl leading-[3rem]'}>
                                                    {item.Text}
                                                </SeoableText>
                                                <Button
                                                    className={'mt-16 w-fit-content'}
                                                    fullWidth={false}
                                                    dark
                                                    onClick={() => router.push(item.ButtonLink)}
                                                >
                                                    {item.ButtonTitle}
                                                </Button>
                                            </div>
                                        </motion.div>
                                    </div>
                                ))}
                            </>
                        }
                    />
                </div>
            </Section>
        );
    }
);

export { SupremeLibrariesBlocks };
