/* eslint-disable max-len */
import { motion } from 'framer-motion';
import { observer } from 'mobx-react';
import React from 'react';

import NextImage from '@/components/image';
import SeoableText from '@/components/SeoableText';
import type { ComponentSectionsTextAndImage } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import { useStore } from '@/stores/RootStore';

type ITextAndImageProps = {
    data: ComponentSectionsTextAndImage;
};

const TextAndImage = observer((props: ITextAndImageProps) => {
    const {
        layoutStore: { isMobile },
    } = useStore();
    const getImage = React.useCallback(() => (isMobile && props.data.MobileImage?.data ? props.data.MobileImage : props.data.Image), [isMobile, props.data]);
    return (
        <div className={'overflow-x-hidden md:my-16'}>
            <Section
                padding={
                    'mt-36 pt-[3.5rem] pb-8 md:pb-16  md:py-16 md:w-full'
                }
                fullWidth={props.data.ImageSticked}
            >
                <div
                    className={
                        `flex w-full flex-col ${props.data.Direction === 'Left' ? 'md:flex-row' : 'md:flex-row-reverse'} justify-between text-white `
                    }
                >
                    <motion.div
                        whileInView={{
                            x: 0,
                            opacity: 1,
                        }}
                        style={{
                            x: props.data.Direction === 'Right' ? -200 : 200,
                            opacity: 0,
                        }}
                        className={
                            `${isMobile ? 'px-16 text-center' : props.data.Direction === 'Left' ? 'ml-[14vw]' : 'mr-[14vw]'} flex ${props.data.ImageSticked ? 'max-w-[44rem]' : 'max-w-[44rem]'} flex-col items-start justify-center md:w-1/2`
                        }
                    >
                        <SeoableText element="span"
                            className={
                                'mx-auto mt-24 mb-[2rem] text-[2.8rem] leading-[2.8rem] md:mx-0 md:mt-0 md:pt-[2.3rem] md:text-[4.8rem] md:leading-[4.8rem]'
                            }
                            style={{ maxWidth: 649 }}
                            dangerouslySetInnerHTML={{ __html: props.data.BigText }}
                        />
                        <SeoableText element="span" className={'text-[2.4rem] leading-[2.8rem] opacity-50 md:text-2xl'}>{props.data.SmallDescription}</SeoableText>

                    </motion.div>

                    <motion.div
                        whileInView={{
                            // offsetDistance: '25vh',
                            x: 0,
                            opacity: 1,
                        }}
                        style={{
                            x: props.data.Direction === 'Right' ? -200 : 200,
                            opacity: 0,
                            height: getImage().data?.attributes?.width === getImage().data?.attributes?.height ? 400 : 'unset',
                        }}
                        transition={{ duration: 0.5 }}
                        className={`${props.data.ImageSticked ? '' : 'md:pr-16'} relative mx-16 mt-32 w-[80vw] md:mx-0 md:mt-0 md:w-1/2`}>
                        <NextImage
                            src={''}
                            priority
                            media={getImage()}
                            alt={props.data.BigText}
                            width={isMobile && props.data.MobileImage?.data ? props.data.MobileImage?.data?.attributes?.width! : props.data.Image.data?.attributes?.width!}
                            height={isMobile ? props.data.MobileImage?.data ? props.data.MobileImage?.data?.attributes?.height! : props.data.Image?.data?.attributes?.height! : props.data.Image.data?.attributes?.width!}
                            layout={getImage().data?.attributes?.width === getImage().data?.attributes?.height ? 'fill' : 'responsive'}
                            objectFit="contain"
                            objectPosition={props.data.ImageSticked ? 'right' : 'center'}
                        />
                    </motion.div>
                </div>
            </Section>
        </div>
    );
});

export { TextAndImage };
