import className from 'classnames';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';

import { Navbar } from '@/modules/navigation/Navbar';
import { useStore } from '@/stores/RootStore';
import { FooterMobile } from '@/templates/FooterMobile';
import { If } from '@/utils/If';
import Responsive from '@/utils/Responsive';

import { Footer } from './Footer';

type IMainProps = {
    children: ReactNode;
    footer?: boolean;
    landingPage?: boolean;
    navigation?: any;
    showNavigation?: boolean;
    global: any;
    pageContext: any;
};

const variants = {
    hidden: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
};

const Main = observer((props: IMainProps) => {
    const { showNavigation = true, footer = true, navigation = null } = props;
    return (
        <div
            className={className(
                'w-full max-w-full text-bpm-white antialiased',
                useStore().layoutStore.isMobile && 'overflow-x-hidden',
                {
                    'md:pt-[6.6rem] pt-[7.7rem]': showNavigation
                }
            )}
        >
            <If condition={showNavigation}>
                <Navbar navigation={navigation.main} pageContext={props.pageContext} />
            </If>
            <motion.div
                variants={variants}
                initial="hidden"
                animate="enter"
                exit="exit"
                transition={{ duration: 0.8, type: 'linear' }}
                className=""
            >
                {props.children}
                <If condition={footer}>
                    <Responsive
                        desktop={<Footer navigation={navigation.footer} copyrights={props.global.attributes.Copyrights} />}
                        mobile={<FooterMobile navigation={navigation.footer} copyrights={props.global.attributes.Copyrights} />}
                    />
                </If>
            </motion.div>
        </div>
    );
});

export { Main };
