import Responsive from '@/utils/Responsive';

import type { ILogoBoxesProps } from './LogoBoxes';
import { LogoBoxes } from './LogoBoxes';
import { LogoBoxesMobile } from './LogoBoxesMobile';

const LogoBoxesWrapper = (props: ILogoBoxesProps) => (
    <Responsive
        desktop={<LogoBoxes {...props} />}
        mobile={<LogoBoxesMobile {...props} />}
    />
);
export default LogoBoxesWrapper;
