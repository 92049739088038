import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState } from 'react';

import type { ICarouselHandle } from '@/components/Carousel/Carousel';
import { Carousel } from '@/components/Carousel/Carousel';
import NextImage from '@/components/image';
import SeoableText from '@/components/SeoableText';
import type { ComponentSectionsTestimonialsSlider } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import { If } from '@/utils/If';

type ITestimonialSliderProps = {
    data: ComponentSectionsTestimonialsSlider;
};

const TestimonialSlider = (props: ITestimonialSliderProps) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
    const carouselRef = useRef<ICarouselHandle>(null);

    if (!props.data.testimonials) {
        return null;
    }
    return (
        <Section
            titleClass={'md:text-[4.8rem] pb-[3.9rem] md:pb-[4rem]'}
            title={props.data.Title}
            padding={'mt-24 md:mt-[14rem] w-full max-w-standard'}
        >
            <div className={'m-8 flex flex-col md:m-0 md:mt-[0.45rem] md:flex-row'}>
                <div className={' md:mr-[4.4rem] md:w-3/5'}>
                    <Carousel ref={carouselRef} onChange={setActiveSlideIndex}>
                        {props.data.testimonials.data.map((item, index: number) => (
                            <div
                                className={'image relative h-full w-full'}
                                key={`Item-${index}`}
                            >
                                <NextImage
                                    src=""
                                    priority
                                    media={item.attributes?.Image}
                                    alt={item.attributes?.Title ?? ''}
                                    layout="responsive"
                                    width={977}
                                    height={900}
                                    objectFit="cover"
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div
                    className={
                        'mt-16 flex flex-col justify-between border-t border-bpm-white border-opacity-30 pt-16 md:mt-0 md:w-1/2 md:border-l md:border-t-0 md:pt-0 md:pl-[3.6rem]'
                    }
                >
                    <div
                        className={
                            'relative mb-32 min-h-[23.5rem] md:mb-0 md:h-1/2 md:min-h-fit'
                        }
                    >
                        <AnimatePresence exitBeforeEnter={true} initial={true}>
                            {props.data.testimonials.data.map((item, index: number) => (
                                index === activeSlideIndex && (
                                    <motion.div
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: 20 }}
                                        transition={{ duration: 0.5 }}
                                        style={{ maxWidth: 700 }}
                                        className={'inset-x-0 top-0 text-bpm-white md:absolute'}
                                        key={`Item-${index}`}
                                    >
                                        <SeoableText element="p"
                                            className={
                                                'pr-[0.3rem] text-2xl-m leading-heroDesc tracking-[-0.009rem] md:pr-[3.3rem] md:text-[2.4rem] md:leading-[3rem] md:tracking-[-0.099rem]'
                                            }
                                        >
                                            {item.attributes?.Sentence}
                                        </SeoableText>
                                        <SeoableText element="span"
                                            className={
                                                'block pt-4 text-lg tracking-[0rem] md:pt-[5.7rem] md:text-2xl'
                                            }
                                        >
                                            {item.attributes?.Name}
                                        </SeoableText>
                                        <If condition={item.attributes?.Title}>
                                            <SeoableText element="span"
                                                className={
                                                    'mt-[-0.3rem] mb-6 block text-lg leading-testJobTitle text-bpm-gray md:mt-[0rem] md:mb-0 md:text-2xl'
                                                }
                                            >
                                                {item.attributes?.Title}
                                            </SeoableText>
                                        </If>
                                    </motion.div>
                                )
                            ))}
                        </AnimatePresence>
                    </div>
                    <div className={'flex flex-row justify-between'}>
                        <div className={'flex items-center text-lg md:text-2xl'}>
                            {`0${activeSlideIndex + 1}`.slice(-2)} /{' '}
                            {`0${props.data.testimonials.data?.length}`.slice(-2)}
                        </div>
                        <div className={'flex flex-row'}>
                            <a
                                onClick={() => {
                                    const notAtTheEnd = activeSlideIndex > 0;
                                    carouselRef?.current?.slideTo(notAtTheEnd ? activeSlideIndex - 1 : props.data.testimonials!.data!.length - 1);
                                }}
                                className={classNames(
                                    'px-[1.8rem] md:px-[3.28rem] py-6 md:py-11 cursor-pointer',
                                    activeSlideIndex > 0 && 'bg-bpm-darkGray'
                                )}
                            >
                                <img
                                    className={''}
                                    src={'/assets/icons/leftWhiteArrow.svg'}
                                    width={14}
                                    height={8}
                                />
                            </a>
                            <a
                                onClick={() => {
                                    const notAtTheEnd = activeSlideIndex
                                        < props.data.testimonials!.data!.length - 1;
                                    carouselRef?.current?.slideTo(notAtTheEnd ? activeSlideIndex + 1 : 0);
                                }}
                                className={classNames(
                                    'px-[1.8rem] md:px-[3.28rem] py-6 md:py-11 cursor-pointer ',
                                    activeSlideIndex < props.data.testimonials.data!.length - 1
                                    && 'bg-bpm-darkGray'
                                )}
                            >
                                <img
                                    className={'rotate-180'}
                                    src={'/assets/icons/leftWhiteArrow.svg'}
                                    width={14}
                                    height={8}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>
                {`
          .btn {
            transition: all 0.2s ease-in-out;
            @apply inline-block text-center;
          }
        `}
            </style>
        </Section>
    );
};

export { TestimonialSlider };
