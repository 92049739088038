import {
    AnimatePresence,
    motion
} from 'framer-motion';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { Button } from '@/components/Button';
import NextImage from '@/components/image';
import SeoableText from '@/components/SeoableText';
import type { ComponentSectionsFeaturesScrollSection } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';

export type IFeaturesScrollBlockProps = {
    data: ComponentSectionsFeaturesScrollSection;
};

const itemTitleVariants = {
    active: { opacity: 1, fontSize: '2.4rem' },
    not: { opacity: 0.5, fontSize: '2.4rem' },
};

const itemTitleVariantsTwo = {
    active: { opacity: 1, fontSize: '2.4rem', marginBottom: '1rem' },
    not: { opacity: 0.5, fontSize: '2.4rem' },
};

const FeaturesScrollSectionStatic = (props: IFeaturesScrollBlockProps) => {
    const [page, setPage] = useState<number>(0);
    const router = useRouter();
    return (
        <Section
            padding={'pt-[5rem] mt-5 relative overflow-x-hidden w-full max-w-standard'}
            title={props.data.InnerTitle ?? ''}
            titleClass='text-[4.8rem] !pb-[4rem]'
        >
            <div className=' bg-[#151515]'
            >
                <div
                    style={{
                        // background:
                        //     props.data.Features.length > 0
                        //         ? props.data.Features[page]!.Background
                        //         : '#FF2F00',
                        transition: 'all 0.3s ease-in-out',
                        width: '100%',
                    }}
                    className={
                        'mx-[4rem] flex w-[fit-content] flex-row items-center justify-between overflow-hidden  text-white'
                    }
                >
                    <div className='max-w-[25.5rem]'>
                        {/* {props.data.InnerTitle && <h4 className='mb-[6rem] text-[5.6rem] leading-[6.4rem]'>{props.data.InnerTitle}</h4>} */}
                        <div
                            className={
                                `relative self-start border-l-2 ${props.data.ImageType === 'Normal' ? 'border-black' : 'min-h-[38rem] border-white'} border-opacity-20 pl-[4.0rem]`
                            }
                        >
                            {props.data.Features.map((item: any, index: number) => (
                                <div
                                    key={`Item-${index}`}
                                    className={`item relative mb-12 h-fit max-w-[70vw] ${index === page && 'active-item'
                                        }`}
                                >
                                    {index === page && (
                                        <motion.div
                                            key={`Item-1-${index}`}
                                            layoutId={`Outline-${index}`}
                                            className={'outline-line'}
                                            initial={true}
                                            style={{
                                                bottom: index === props.data.Features.length - 1 ? -50 : 0,
                                                position: 'absolute',
                                                left: -42,
                                                top: index !== 0 ? 10 : 0,
                                                // maxHeight: 200,
                                                border: `1px solid ${props.data.ImageType === 'Normal' ? 'black' : props.data.SideLineColor}`,
                                            }}
                                            transition={{}}
                                        />
                                    )}

                                    <motion.div
                                        key={`Item-2-${index}`}
                                        initial={'not'}
                                        variants={props.data.ImageType === 'Normal' ? itemTitleVariants : itemTitleVariantsTwo}
                                        animate={index === page ? 'active' : 'not'}
                                        transition={{
                                            duration: 0.5,
                                        }}

                                        onClick={() => setPage(index)}
                                    >
                                        <SeoableText element="h2" className={'block cursor-pointer text-[2.4rem] tracking-[-0.08rem]'}
                                            dangerouslySetInnerHTML={{ __html: item.Title }} />
                                    </motion.div>

                                    <motion.div
                                        key={`Item-3-${index}`}
                                        style={{ height: index === page ? 'fit-content' : 0 }}
                                    >
                                        <AnimatePresence
                                            presenceAffectsLayout={true}
                                            initial={false}
                                            exitBeforeEnter={true}
                                            key={`Item-4-${index}`}
                                        >
                                            {index === page && (
                                                <motion.div
                                                    initial={{ height: 0 }}
                                                    animate={{ height: 'fit-content' }}
                                                    exit={{ opacity: 0 }}
                                                    key={`Item-5-${index}`}>
                                                    <motion.div
                                                        key={`Item-3-${index}`}
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: props.data.ImageType === 'Normal' ? 1 : 0.5 }}
                                                        exit={{ opacity: 0 }}
                                                        style={{ maxWidth: props.data.ImageType === 'Normal' ? 255 : 400, lineHeight: '2.4rem' }}
                                                        transition={{
                                                            duration: 1,
                                                        }}
                                                        className={'mb-[2rem] max-w-[25.5rem] text-[1.7rem] leading-[2.4rem]'}
                                                    >
                                                        <SeoableText element="p">{item.Text}</SeoableText>
                                                    </motion.div>
                                                    <motion.div
                                                        key={`Item-4-${index}`}
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        exit={{ opacity: 0 }}
                                                        transition={{
                                                            duration: 0.3,
                                                        }}
                                                        className={'button'}
                                                    >
                                                        {item.ButtonDisabled ? (
                                                            <Button disabled dark={props.data.ImageType === 'Normal'}>
                                                                {item.ButtonText}
                                                            </Button>
                                                        ) : (
                                                            <Button onClick={() => item.ButtonLink && router.push(item.ButtonLink)} dark={props.data.ImageType === 'Normal'}>{item.ButtonText}</Button>
                                                        )}
                                                    </motion.div>
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </motion.div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className={'relative py-[2rem]'}
                        style={{
                            width: '100%', height: 'fit-content', maxWidth: '52%'
                        }}
                    >
                        <AnimatePresence
                            presenceAffectsLayout={false}
                            initial={true}
                            exitBeforeEnter={true}
                        >
                            {props.data.Features.map((item: any, index: number) => page === index && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                    key={`$ImWrap-${index}`} className={'h-fit w-full'}>
                                    <NextImage
                                        src={''}
                                        priority
                                        media={item ? item.Image : ''}
                                        alt={item ? item.Title : ''}
                                        width={516}
                                        height={516}
                                        layout="responsive"
                                        objectFit='contain'
                                        objectPosition='right middle'
                                    />
                                </motion.div>
                            ))}
                        </AnimatePresence>
                    </div>
                </div>
                <style jsx>
                    {`
            .outline-line {
              position: absolute;
              top: -20px;
              left: -20px;
              right: -20px;
              bottom: -20px;
              border: 10px solid white;
              border-radius: 50%;
            }

            .item p {
              max-width: 238px;
            }

            .item p,
            .item .button {
              @apply opacity-0;
            }

            .active-item p,
            .active-item .button {
              @apply opacity-100;
            }
          `}
                </style>
            </div>
            {
                props.data.Features.map((item: any, index: number) => page === index && (
                    <div key={`$ImWrap2-${index}`} className={''}>
                        <NextImage
                            src={''}
                            priority
                            media={item.Image}
                            alt={item.Title}
                            width={1}
                            height={1}
                            layout="fixed"
                        />
                    </div>
                ))
            }
        </Section >
    );
};
export { FeaturesScrollSectionStatic };
