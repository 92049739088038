export function redirectionTargetToUrl(redirectionTarget?: string) {
    switch (redirectionTarget) {
        case 'Artist':
            return (process.env.NEXT_PUBLIC_REDIRECT_ARTIST_URL || '/');
        case 'Create':
            return (process.env.NEXT_PUBLIC_REDIRECT_CREATE_URL || '/');
        case 'Supreme':
        default:
            return (process.env.NEXT_PUBLIC_REDIRECT_SUPREME_URL || '/');
    }
}

export function redirectionTargetToAPIRedirect(redirectionTarget?: string) {
    switch (redirectionTarget) {
        case 'Artist':
            return (process.env.NEXT_PUBLIC_REDIRECT_ARTIST_URL || '/');
        case 'Create':
            return (process.env.NEXT_PUBLIC_REDIRECT_CREATE_URL || '/');
        case 'Supreme':
        default:
            return 'supreme';
    }
}
