import { observer } from 'mobx-react';

import { Button } from '@/components/Button';
import NextImage from '@/components/image';
import type { ComponentSectionsGetTheApp, GlobalEntity } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import { useStore } from '@/stores/RootStore';

type IGetTheAppProps = {
    global: GlobalEntity;
    data: ComponentSectionsGetTheApp;
};

const GetTheApp = observer((props: IGetTheAppProps) => {
    const {
        layoutStore: { isMobile },
    } = useStore();
    return (
        <div className={'md:mx-16'}>
            <Section
                style={{ background: props.data.Background }}
                padding={
                    'mt-[11rem] md:mb-[30rem] md:mb-[0] md:mt-[20rem] mx-8 md:mx-auto md:px-16 px-8 pt-16 md:w-full'
                }
            >
                <div
                    className={
                        'flex w-full flex-col justify-between text-black md:flex-row '
                    }
                >
                    <div
                        className={
                            'mb-16 flex flex-col items-center justify-between text-center md:w-1/2 md:items-start md:text-left '
                        }
                    >
                        <span className={'text-lg leading-[2.4rem] md:text-2xl'}>{props.data.SmallTitle}</span>
                        <span
                            className={
                                'mt-24 font-gravity text-6xl-m leading-hero-sm md:mt-0 md:text-11xl md:leading-callToAction '
                            }
                            style={{ maxWidth: 641 }}
                            dangerouslySetInnerHTML={{ __html: props.data.BigTitle }}
                        />
                        <div className={'mt-16 flex flex-row justify-between md:mt-0'}>
                            <Button onClick={() => window.open(props.global.attributes?.iOSStoreLink ?? '', 'blank')} className='mr-[1rem]' dark>App Store</Button>
                            <Button onClick={() => window.open(props.global.attributes?.GooglePlayStoreLink ?? '', 'blank')} dark>Google Play</Button>
                        </div>
                    </div>

                    <div className={'relative mt-0 md:mt-0 md:w-1/2 md:pt-20'}>
                        <NextImage
                            src=""
                            priority
                            media={props.data.Image}
                            alt={props.data.BigTitle}
                            width={isMobile ? 315 : 880}
                            height={isMobile ? 305 : 820}
                            layout="responsive"
                            objectFit="contain"
                        />
                    </div>
                </div>
            </Section>
        </div>
    );
});

export { GetTheApp };
