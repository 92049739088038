import { observer } from 'mobx-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { Button } from '@/components/Button';
import type { ComponentSectionsPricing } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import { MobileHorizontalSlider } from '@/modules/MobileHorizontalSlider/MobileHorizontalSlider';
import { useStore } from '@/stores/RootStore';
import Responsive from '@/utils/Responsive';

type IPricingTableProps = {
    data: ComponentSectionsPricing;
};

const PricingTable = observer((props: IPricingTableProps) => {
    const {
        layoutStore: { accent },
    } = useStore();
    const router = useRouter();
    const [selectedCat, setSelectedCat] = useState<number>(0);

    useEffect(() => {
        if (router.asPath.includes('latino') && props.data.pricing_categories?.data && props.data.pricing_categories?.data.length > 1) { // ToDo: Temp, really bad
            setSelectedCat(1);
        }
    }, []);

    return (
        <Section
            title={props.data.Title}
            smallTitle={props.data.SmallSectionTitle}
            padding={
                'md:mt-[30rem] mt-[20rem] ml-8 md:ml-unset md:mx-auto mb-[0rem]  md:w-full w-[95vw] md:px-16 max-w-standard'
            }
        >
            {(props.data.pricing_categories?.data.length || 0) > 1 && (
                <div className='mx-auto mb-[4rem] w-[fit-content]'>
                    <div className='mx-auto flex flex-row space-x-[3rem] rounded-[2rem] border-[0.1rem] border-[#2A2A2A] bg-bpm-darkGray p-[0.4rem] text-bpm-white'>{props.data.pricing_categories?.data.map((category, index) => <span onClick={() => setSelectedCat(index)} className={`inline-block cursor-pointer rounded-[2rem] py-[0.3rem] px-[1.35rem] text-[1.2rem] ${selectedCat === index ? 'bg-[#4A4A4A] text-bpm-white ' : 'text-[#919191] '}`} key={category.attributes?.Name}>{category.attributes?.Name}</span>)}</div>
                </div>
            )}
            <div
                className={
                    'mt-8 flex w-full flex-col justify-center text-black md:flex-row'
                }
            >

                <Responsive
                    mobile={
                        <>
                            <MobileHorizontalSlider
                                accent={accent}
                                items={props.data.pricing_categories?.data[selectedCat]?.attributes?.pricing_plans?.data
                                    .sort((i1, i2) => ((i1.attributes?.MonthPrice || 0) - (i2.attributes?.MonthPrice || 0))).map(
                                        (item, index: number) => (
                                            <div
                                                key={`Parent-${index}`}
                                                className={
                                                    'flex  flex-col items-center justify-between border-[0.1rem] border-[#2A2A2A] bg-bpm-darkGray p-[2rem] text-bpm-white'
                                                }
                                            >
                                                <span className={'mb-[1rem] text-[2rem] text-[#8A8C8B]'}>{item.attributes?.Title}</span>
                                                {item.attributes?.FirstMonthPrice && <span className={'text-[3.6rem]'}>
                                                    {(/\d/.test(item.attributes?.FirstMonthPrice)) ? `$${item.attributes?.FirstMonthPrice}` : item.attributes?.FirstMonthPrice}
                                                </span>}
                                                {item.attributes && item.attributes.ShowMonthly ? <>
                                                    <span className={'mt-[1rem] text-[1.1rem] opacity-70'}>
                                                        First Month* ${item.attributes?.MonthPrice}/month after
                                                    </span></> : <div className='h-[2.7rem] w-full' />}

                                                <div className={'mt-[4.7rem] w-full border-t-[0.1rem] border-[#2A2A2A] pt-[3.2rem]'}>
                                                    {item.attributes?.EverythingInText && <span className='text-[1.4rem] leading-[2.6rem]'>{item.attributes.EverythingInText}</span>}
                                                    {item.attributes?.Features.map(
                                                        (option: any, i: number) => (
                                                            <div
                                                                key={`Child-${index}-${i}`}
                                                                className={
                                                                    'flex flex-row items-center justify-between  py-[0.8rem]'
                                                                }
                                                            >
                                                                <span
                                                                    className={`text-[1.4rem] text-bpm-white opacity-70 ${option?.StrikedOut ? 'line-through opacity-40' : ''}`}
                                                                >
                                                                    {option.Name}
                                                                </span>
                                                                {(!option?.StrikedOut) && <div
                                                                    className={
                                                                        'flex h-[1.7rem] w-[1.7rem] items-center justify-center rounded-[10rem] bg-bpm-gray bg-opacity-40'
                                                                    }
                                                                >
                                                                    <img
                                                                        className={''}
                                                                        src={'/assets/icons/whiteCheckMark.svg'}
                                                                    />
                                                                </div>}
                                                            </div>
                                                        )
                                                    )}
                                                    <Link href={item.attributes?.ButtonLink!}>
                                                        <a><Button className={'mt-24'} fullWidth>
                                                            {'Get Started'}
                                                        </Button></a>
                                                    </Link>

                                                </div>
                                            </div>
                                        )
                                    )}
                            />
                        </>
                    }
                    desktop={
                        <div className='flex w-full flex-row space-x-[2rem]'>
                            {props.data.pricing_categories?.data[selectedCat]?.attributes?.pricing_plans?.data
                                .sort((i1, i2) => ((i1.attributes?.MonthPrice || 0) - (i2.attributes?.MonthPrice || 0))).map((item, index: number) => (
                                    <div
                                        key={`Parent-${index}`}
                                        className={
                                            'flex  w-[33%] flex-col items-center   bg-bpm-darkGray p-[2rem] text-bpm-white'
                                        }
                                    >
                                        <span className={'mb-[1rem] text-[2rem] text-[#8A8C8B]'}>{item.attributes?.Title}</span>
                                        {item.attributes?.FirstMonthPrice && <span className={'text-[3.6rem]'}>
                                            {(/\d/.test(item.attributes?.FirstMonthPrice)) ? `$${item.attributes?.FirstMonthPrice}` : item.attributes?.FirstMonthPrice}
                                        </span>}
                                        {item.attributes && item.attributes.ShowMonthly ? <>
                                            <span className={'mt-[1rem] text-[1.1rem] opacity-70'}>
                                                First Month* ${item.attributes?.MonthPrice}/month after
                                            </span></> : <div className='h-[2.7rem] w-full' />}

                                        {/* {item.attributes?.Badge && <div style={{ background: item.attributes?.BedgeBG ? item.attributes?.BedgeBG : '#697D7A' }} className={'mt-[5rem] w-full py-[1.3rem] text-center text-black'}>{item.attributes?.Badge}</div>} */}

                                        <div className={'mt-[4.7rem] w-full border-t-[0.1rem] border-[#2A2A2A] pt-[3.2rem]'}>
                                            {item.attributes?.EverythingInText && <span className='text-[1.4rem] leading-[2.6rem]'>{item.attributes.EverythingInText}</span>}
                                            {item.attributes?.Features.map(
                                                (option: any, i: number) => (
                                                    <div
                                                        key={`Child-${index}-${i}`}
                                                        className={
                                                            'flex flex-row items-center justify-between  py-[0.8rem]'
                                                        }
                                                    >
                                                        <span
                                                            className={`text-[1.4rem] text-bpm-white opacity-70 ${option?.StrikedOut ? 'line-through opacity-40' : ''}`}
                                                        >
                                                            {option.Name}
                                                        </span>
                                                        {(!option?.StrikedOut) && <div
                                                            className={
                                                                'flex h-[1.7rem] w-[1.7rem] items-center justify-center rounded-[10rem] bg-bpm-gray bg-opacity-40'
                                                            }
                                                        >
                                                            <img
                                                                className={''}
                                                                src={'/assets/icons/whiteCheckMark.svg'}
                                                            />
                                                        </div>}
                                                    </div>
                                                )
                                            )}
                                            <Link href={item.attributes?.ButtonLink!}>
                                                <a>
                                                    <Button className={'mt-24'} fullWidth>
                                                        {'Get Started'}
                                                    </Button>
                                                </a>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    }
                />
            </div>
        </Section>
    );
});

export { PricingTable };
