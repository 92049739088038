import { wrap } from '@motionone/utils';
import {
    motion, useAnimationFrame, useMotionValue, useScroll, useSpring, useTransform, useVelocity
} from 'framer-motion';
import { observer } from 'mobx-react';
import { useRef } from 'react';

import NextImage from '@/components/image';
import type { ComponentElementsRunnerGenre, ComponentSectionsGenresRunner, Maybe } from '@/generated/graphql-types';
import { useStore } from '@/stores/RootStore';

type IGenresRunnerProps = {
    data: ComponentSectionsGenresRunner;
};

interface ParallaxProps {
    childrens: Array<Maybe<ComponentElementsRunnerGenre>> | Maybe<Array<Maybe<ComponentElementsRunnerGenre>>> | undefined;
    baseVelocity: number;
    isMobile: boolean;
}

function ParallaxRow({ childrens, baseVelocity = 100, isMobile = false }: ParallaxProps) {
    const baseX = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
        damping: 50,
        stiffness: 400
    });
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
        clamp: false
    });
    const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

    const directionFactor = useRef<number>(1);
    const prevT = useRef<number>(0);
    useAnimationFrame((t) => {
        if (!prevT.current) prevT.current = t;

        const timeDelta = t - prevT.current;
        let moveBy = directionFactor.current * baseVelocity * (timeDelta / 1000);

        /**
       * This is what changes the direction of the scroll once we
       * switch scrolling directions.
       */
        if (velocityFactor.get() < 0) {
            directionFactor.current = -1;
        } else if (velocityFactor.get() > 0) {
            directionFactor.current = 1;
        }

        moveBy += directionFactor.current * moveBy * velocityFactor.get();

        baseX.set(baseX.get() + moveBy);

        prevT.current = t;
    });

    /**
     * The number of times to repeat the child text should be dynamically calculated
     * based on the size of the text and viewport. Likewise, the x motion value is
     * currently wrapped between -20 and -45% - this 25% is derived from the fact
     * we have four children (100% / 4). This would also want deriving from the
     * dynamically generated number of children.
     */
    return (
        <div className="parallax mb-[4rem] text-bpm-white md:mb-[8rem]">
            <motion.div className="scroller" style={{ x, display: 'flex' }}>
                {[...(childrens as any), ...(childrens as any), ...(childrens as any), ...(childrens as any)]?.map((item) => <div className={'genre text-[4rem] leading-[4rem] md:text-[8rem] md:leading-[8rem]'} key={item?.id}>
                    <div style={{
                        width: isMobile ? 44 : 88, height: isMobile ? 44 : 88, marginRight: isMobile ? '2rem' : '4rem', marginLeft: isMobile ? '2rem' : '4rem'
                    }}>
                        <NextImage
                            src=""
                            media={item?.Image}
                            alt={''}
                            layout="responsive"
                            width={88}
                            height={88}
                            priority
                        />
                    </div>
                    {item?.Genre}
                </div>)}
            </motion.div>
            <style jsx>
                {`
          .parallax {
            overflow: hidden;
            letter-spacing: -2px;
            line-height: 0.8;
            
            white-space: nowrap;
            display: flex;
            flex-wrap: nowrap;
          }
          .genre {
            display: flex;
          }
          
        `}
            </style>
        </div>
    );
}

const GenresRunner = observer((props: IGenresRunnerProps) => {
    const {
        layoutStore: { isMobile },
    } = useStore();
    return (
        <div className='mt-[10rem] mb-0 md:mt-[30rem]'>
            {props.data.Headline && <h3 className='mb-[6rem] block text-center text-[1.8rem] leading-[2rem] md:mb-[14rem] md:text-[5.6rem] md:leading-[2.8rem]'>{props.data.Headline}</h3>}
            <ParallaxRow isMobile={isMobile} baseVelocity={-1} childrens={props.data.FirstRow} />
            <ParallaxRow isMobile={isMobile} baseVelocity={1} childrens={props.data.SecondRow} />
            <ParallaxRow isMobile={isMobile} baseVelocity={-2} childrens={props.data.ThirdRow} />
        </div >
    );
});

export { GenresRunner };
