import className from 'classnames';
import Link from 'next/link';

import NextImage from '@/components/image';
import SeoableText from '@/components/SeoableText';
import type { ComponentSectionsFeaturesCards } from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import { If } from '@/utils/If';

export type IDataGridSection = {
    data: ComponentSectionsFeaturesCards;
};

const DataGridSection = (props: IDataGridSection) => (
    <Section padding={'pt-64 mt-5 w-full px-16 max-w-standard'} title={props.data.BigTitle} smallTitle={props.data.SmallTitle}>
        <div className={'wrapper'}>
            <div className='flex flex-row justify-between space-x-[2rem]'>
                <div className='w-[57%] bg-bpm-darkGray'>
                    <div className={className('text mb-16 mt-[4rem] ml-[4rem]', !Link && 'h-fit')}>
                        <div>
                            <SeoableText element="h2" className="text-[2.6rem] leading-[3.2rem]">{props.data.TopLeftCard.Title}</SeoableText>
                            <SeoableText element="p" className='mt-[1rem] max-w-[300px] text-[1.7rem] leading-[2.4rem] text-bpm-white opacity-50' dangerouslySetInnerHTML={{ __html: props.data.TopLeftCard.Text }} />
                        </div>
                        <If condition={Link}>
                            <Link href={props.data.TopLeftCard.Link}>
                                <a className={'action mt-[1rem] flex cursor-pointer items-center text-[1.6rem] leading-[2.8rem] text-bpm-white'}>Learn More <img
                                    src={'/assets/icons/leftWhiteArrow.svg'}
                                    className={'ml-[1rem] inline-block rotate-180'}
                                    width={8}
                                    height={4}
                                /></a>
                            </Link>
                        </If>
                    </div>
                    <div className={'image relative h-[25.6rem] w-[100%]'}>
                        <NextImage
                            src={''}
                            priority
                            media={props.data.TopLeftCard.Image}
                            alt={props.data.TopLeftCard.Title}
                            layout="fill"
                            objectFit="contain"
                        />
                    </div>
                </div>
                <div className='w-[43%] bg-bpm-darkGray'>
                    <div className={className('text mb-16 mt-[4rem] ml-[4rem]', !Link && 'h-fit')}>
                        <div>
                            <SeoableText element="h2" className="text-[2.6rem] leading-[3.2rem]">{props.data.TopRightCard.Title}</SeoableText>
                            <SeoableText element="p" className='mt-[1rem] max-w-[300px] text-[1.7rem] leading-[2.4rem] text-bpm-white opacity-50' dangerouslySetInnerHTML={{ __html: props.data.TopRightCard.Text }} />
                        </div>
                        <If condition={Link}>
                            <Link href={props.data.TopRightCard.Link}>
                                <a className={'action mt-[1rem] flex cursor-pointer items-center text-[1.6rem] leading-[2.8rem] text-bpm-white'}>Learn More <img
                                    src={'/assets/icons/leftWhiteArrow.svg'}
                                    className={'ml-[1rem] inline-block rotate-180'}
                                    width={8}
                                    height={4}
                                /></a>
                            </Link>
                        </If>
                    </div>
                    <div className={'image relative h-[25.6rem] w-[100%]'}>
                        <NextImage
                            src={''}
                            priority
                            media={props.data.TopRightCard.Image}
                            alt={props.data.TopRightCard.Title}
                            layout="fill"
                            objectFit="contain"
                        />
                    </div>
                </div>
            </div>
            <div className='mt-[2rem] flex flex-row justify-between space-x-[2rem]'>
                <div className='w-[43%] bg-bpm-darkGray'>
                    <div className={className('text mb-16 mt-[4rem] ml-[4rem]', !Link && 'h-fit')}>
                        <div>
                            <SeoableText element="h2" className="text-[2.6rem] leading-[3.2rem]">{props.data.BottomLeftCard.Title}</SeoableText>
                            <SeoableText element="p" className='mt-[1rem] max-w-[268px] text-[1.7rem] leading-[2.4rem] text-bpm-white opacity-50' dangerouslySetInnerHTML={{ __html: props.data.BottomLeftCard.Text }} />
                        </div>
                        <If condition={Link}>
                            <Link href={props.data.BottomLeftCard.Link}>
                                <a className={'action mt-[1rem] flex cursor-pointer items-center text-[1.6rem] leading-[2.8rem] text-bpm-white'}>Learn More <img
                                    src={'/assets/icons/leftWhiteArrow.svg'}
                                    className={'ml-[1rem] inline-block rotate-180'}
                                    width={8}
                                    height={4}
                                /></a>
                            </Link>
                        </If>
                    </div>
                    <div className={'image relative h-[25.6rem] w-[100%]'}>
                        <NextImage
                            src={''}
                            priority
                            media={props.data.BottomLeftCard.Image}
                            alt={props.data.BottomLeftCard.Title}
                            layout="fill"
                            objectFit="contain"
                        />
                    </div>
                </div>
                <div className='w-[57%] bg-bpm-darkGray'>
                    <div className={className('text mb-16 mt-[4rem] ml-[4rem]', !Link && 'h-fit')}>
                        <div>
                            <SeoableText element="h2" className="text-[2.6rem] leading-[3.2rem]">{props.data.BottomRight.Title}</SeoableText>
                            <SeoableText element="p" className='mt-[1rem] max-w-[300px] text-[1.7rem] leading-[2.4rem] text-bpm-white opacity-50' dangerouslySetInnerHTML={{ __html: props.data.BottomRight.Text }} />
                        </div>
                        <If condition={Link}>
                            <Link href={props.data.BottomRight.Link}>
                                <a className={'action mt-[1rem] flex cursor-pointer items-center text-[1.6rem] leading-[2.8rem] text-bpm-white'}>Learn More <img
                                    src={'/assets/icons/leftWhiteArrow.svg'}
                                    className={'ml-[1rem] inline-block rotate-180'}
                                    width={8}
                                    height={4}
                                /></a>
                            </Link>
                        </If>
                    </div>
                    <div className={'image relative h-[25.6rem] w-[100%]'}>
                        <NextImage
                            src={''}
                            priority
                            media={props.data.BottomRight.Image}
                            alt={props.data.BottomRight.Title}
                            layout="fill"
                            objectFit="contain"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Section>
);

export { DataGridSection };
