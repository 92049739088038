import Head from 'next/head';
import { NextSeo } from 'next-seo';
import PropTypes from 'prop-types';

import { getStrapiMedia } from '@/utils/media';
import { mediaPropTypes } from '@/utils/types';

const Seo = ({ metadata }: { metadata: any }) => {
    // Prevent errors if no metadata was set
    if (!metadata) return null;
    return (
        <>
            <Head>
                {metadata.ShowZendeskChat && <script type="text/javascript" async={false} id="ze-snippet" src={`https://static.zdassets.com/ekr/snippet.js?key=${metadata.ZendeskChatTagId}`}></script>}

                {metadata.klaviyoApiKey && <script type="text/javascript" async={false} src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${metadata.klaviyoApiKey}`}></script>}

                {metadata.favicon && metadata.favicon.data && <link
                    key="favicon"
                    rel="shortcut icon"
                    href={getStrapiMedia(metadata.favicon.data.attributes.url) ?? ''}
                />}
                <meta name="facebook-domain-verification" content="hvns6xjh1vcsdgsse7jkhasnhfho9j" />
                <script async src={`https://www.googletagmanager.com/gtag/js?id=${metadata.GoogleTagManagerID}`}></script>
                <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                        __html: `window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${metadata.GoogleTagManagerID}');`,
                    }}
                ></script>
                <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                        __html: `!function (f, b, e, v, n, t, s) {
            if (f.fbq)
            return;
            n = f.fbq = function () {
            n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
            if (!f._fbq)
            f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
          }(window,
            document, 'script', ' https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${metadata.FacebookPixeliD}');
            fbq('track', "PageView");`,
                    }}
                ></script>
            </Head>
            <noscript>
                <img
                    height="1"
                    width="1"
                    style={{ display: 'none' }}
                    src={`https://www.facebook.com/tr?id=${metadata.FacebookPixeliD}&ev=PageView&noscript=1`}
                />
            </noscript>
            <noscript>
                <iframe
                    src={`https://www.googletagmanager.com/ns?id=${metadata.GoogleTagManagerID}`}
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}
                ></iframe>
            </noscript>
            <NextSeo
                title={metadata.metaTitle}
                description={metadata.metaDescription}
                openGraph={{
                    // Title and description are mandatory
                    title: metadata.metaTitle,
                    description: metadata.metaDescription,
                    // Only include OG image if we have it
                    // Careful: if you disable image optimization in Strapi, this will break
                    ...(metadata.shareImage && metadata.shareImage.data.attributes.formats.large && {
                        images: [{
                            url: getStrapiMedia(metadata.shareImage.data.attributes.formats.large.url) || '',
                            width: metadata.shareImage.data.attributes.formats.large.width || 0,
                            height: metadata.shareImage.data.attributes.formats.large.height || 0,
                        }],
                    }),
                }}
                // Only included Twitter data if we have it
                twitter={{
                    ...(metadata.twitterCardType && {
                        cardType: metadata.twitterCardType,
                    }),
                    // Handle is the twitter username of the content creator
                    ...(metadata.twitterUsername && { handle: metadata.twitterUsername }),
                }}
            />
        </>
    );
};

Seo.propTypes = {
    metadata: PropTypes.shape({
        metaTitle: PropTypes.string.isRequired,
        metaDescription: PropTypes.string.isRequired,
        shareImage: mediaPropTypes,
        favicon: mediaPropTypes,
        twitterCardType: PropTypes.string,
        twitterUsername: PropTypes.string,
        FacebookPixeliD: PropTypes.string,
        GoogleTagManagerID: PropTypes.string,
        ShowZendeskChat: PropTypes.bool,
        ZendeskChatTagId: PropTypes.string,
    }),
};

export default Seo;
