import type { MotionValue } from 'framer-motion';
import {
    motion, useScroll, useTransform
} from 'framer-motion';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';

import NextImage from '@/components/image';
import type {
    ComponentElementsTracksParallaxItem,
    ComponentSectionsTracksParallaxBox,
} from '@/generated/graphql-types';
import { Section } from '@/layouts/Section';
import { useStore } from '@/stores/RootStore';

type ITracksParallaxBoxProps = {
    data: ComponentSectionsTracksParallaxBox;
};

type IParallaxedItemProps = {
    index: number;
    rowNumber?: number;
    children: ReactNode;
    mobile: boolean;
    parentRef: any;
};

function useParallax(value: MotionValue<number>, distance: number) {
    return useTransform(value, [0, 1], [-distance, distance]);
}

const ParallaxedItem = (props: IParallaxedItemProps) => {
    const ref = useRef(null);
    const { children } = props;
    const { scrollYProgress } = useScroll({ offset: ['start end', 'end end'], target: ref, container: props.parentRef });

    const y = useParallax(scrollYProgress, props.index % 2 === 0 ? props.mobile ? 60 : -60 : 0);

    return (
        <>
            <div ref={ref} />
            <motion.div
                className={
                    'mr-8 w-[18.2rem] min-w-[18.2rem] md:mr-16 md:w-[22.4vw] md:max-w-[43rem]'
                }
                transition={{ duration: 0.1, ease: 'linear' }}
                style={{ y, translateY: (props.index % 2 === 0 ? props.mobile ? -60 : 60 : 0) }}
            >
                {children}
            </motion.div></>
    );
};

const TracksParallaxBox = observer((props: ITracksParallaxBoxProps) => {
    const [arrays, setArrays] = useState<ComponentElementsTracksParallaxItem[][]>(
        []
    );

    const ref = useRef(null);

    const {
        layoutStore: { isMobile },
    } = useStore();

    useEffect(() => {
        const tempArrays: any = [];
        const size = 5;
        while (props.data.Items.length > 0) tempArrays.push(props.data.Items.splice(0, size));
        setArrays(tempArrays);
    }, [props.data.Items]);

    return (
        <Section
            ref={ref}
            fullWidth
            key={arrays.length}
            titleClass='!pb-0'
            padding={
                'mt-[14rem] md:mt-[20rem] md:mx-auto  overflow-hidden relative '
            }
            title={props.data.Title}
        >
            {/* <span
                className={
                    'block text-center text-[3.2rem] leading-[3.2rem] text-bpm-gray md:text-[8rem] md:leading-[8.8rem]'
                }
            >
                <Responsive
                    mobile={
                        <h1>
                            Exclusive <span className={'text-bpm-white'}>Playlists</span>{' '}
                            <br />
                            and Mix-Ready <br />
                            <span className={'text-bpm-white'}>Curated Sets</span> For DJs
                        </h1>
                    }
                    desktop={
                        <h1>
                            Exclusive <span className={'text-bpm-white'}>Playlists</span> and
                            <br />
                            Mix-Ready{' '}
                            <span className={'text-bpm-white'}>
                                Curated Sets
                                <br />
                            </span>{' '}
                            For DJs
                        </h1>
                    }
                />
            </span> */}

            <div className={'mt-[14rem]'}>
                {arrays.map(
                    (arr: ComponentElementsTracksParallaxItem[], index: number) => (arr.length === 5 ? (
                        <div
                            key={`PpItemsTop-${index}`}
                            className={`mx-auto mb-[14rem] flex w-[123vw] md:mb-[10rem]  ${index % 2 === 0
                                ? 'translate-x-[-57vw] justify-center md:translate-x-[-11vw]'
                                : 'translate-x-[-57vw] justify-center md:translate-x-[-19vw]'
                                }`}
                        >
                            {arr.map(
                                (item: ComponentElementsTracksParallaxItem, i: number) => (
                                    <ParallaxedItem
                                        index={i}
                                        rowNumber={index}
                                        parentRef={ref}
                                        mobile={isMobile}
                                        key={`PpItems-${i}`}
                                    >
                                        <div className={'relative'}>
                                            <NextImage
                                                src=""
                                                priority
                                                media={item.Image}
                                                alt={item.Title}
                                                layout="responsive"
                                                width={430}
                                                height={430}
                                                objectFit="cover"
                                            />
                                        </div>
                                        <div>
                                            <span className={'mt-8 block text-xl text-bpm-white'}>
                                                {item.Title}
                                            </span>
                                            <span
                                                className={
                                                    'block text-xl text-bpm-white opacity-50'
                                                }
                                            >
                                                {item.Genre}
                                            </span>
                                        </div>
                                    </ParallaxedItem>
                                )
                            )}
                        </div>
                    ) : null)
                )}
            </div>
        </Section>
    );
});

export { TracksParallaxBox };
