import className from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { supremeConfig } from '@/api/config';
import { errorToString } from '@/api/error';
import { MarketingApi } from '@/api/supreme-api-sdk/src';
import type { ButtonRef } from '@/components/Button';
import { Button } from '@/components/Button';
import NextImage from '@/components/image';
import SeoableText from '@/components/SeoableText';
import { useStore } from '@/stores/RootStore';

import styles from './Hero.module.scss';

type Inputs = {
    email: string;
};

const apiClient = new MarketingApi(supremeConfig);

const Hero = observer((props: any) => {
    const ref = useRef<HTMLElement>(null);
    const [height, setHeight] = useState<number>(0);
    const router = useRouter();
    const {
        layoutStore: { isMobile },
    } = useStore();
    const {
        mobilePicture,
        picture,
        title,
        mobileTitle,
        CubeBackgroundColor: { color: backgroundColor },
        text,
        action,
        ActionTwo,
        isPromotionForm,
        marketingPlatform = '',
        marketingType = '',
    } = props.data;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();

    const buttonRef = useRef<ButtonRef>(null);

    useEffect(() => {
        ref && ref.current && setHeight(ref.current.clientHeight);
    });

    const onSubmit: SubmitHandler<Inputs> = async (data: any) => {
        buttonRef?.current?.startLoading();

        try {
            await apiClient.subscribeNotAuthenticatedUserMarketing({
                email: data.email,
                platform: marketingPlatform,
                type: marketingType,
            });
            buttonRef?.current?.stopLoadingWithSuccess();
            // toast.success('Thank you, we will be in touch', {
            //     position: 'bottom-center',
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     progress: undefined,
            //     theme: 'dark',
            // });
        } catch (error: any) {
            buttonRef?.current?.stopLoading();
            toast.error(await errorToString(error), {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: 'dark',
            });
        }
    };

    return (
        <header
            style={{
                maxHeight: isMobile ? '-webkit-fill-available' : '91.6rem',
                minHeight: 600,
                height: isMobile
                    ? 'calc(100vh - 8rem)'
                    : 'calc(100vh - 6.7rem)',
            }}
            className={'hero-action relative h-screen w-screen max-w-full'}
            ref={ref}
        >
            <div>
                <NextImage
                    src=''
                    media={
                        isMobile && mobilePicture.data ? mobilePicture : picture
                    }
                    alt={title}
                    layout='fill'
                    objectFit='cover'
                    priority
                />
            </div>

            {!isMobile && <div
                onClick={() => {
                    window.scrollTo({ behavior: 'smooth', top: height });
                }}
                className='changeSvgOnHover absolute right-[4rem] bottom-[4rem] z-10 flex h-[4rem] w-[10rem] cursor-pointer items-center justify-center rounded-[10rem] bg-black text-[1.6rem] transition-all hover:bg-[#E6F0E9] hover:text-black'
            >
                Scroll
                <svg
                    width='10'
                    className='ml-4'
                    height='12'
                    viewBox='0 0 12 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        d='M5.87024 0L5.87024 10.5595'
                        stroke='#D9D9D9'
                        strokeWidth='2'
                    />
                    <path
                        d='M0.960938 6.49805L6.4619 11.9999'
                        stroke='#D9D9D9'
                        strokeWidth='2'
                    />
                    <path
                        d='M11.0001 6.49805L5.49916 11.9999'
                        stroke='#D9D9D9'
                        strokeWidth='2'
                    />
                </svg>
            </div>}

            <motion.div
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 20 }}
                exit={{ opacity: 0, y: 0 }}
                transition={{ ease: 'easeInOut', duration: 0.8, delay: 0.5 }}
                className={className(
                    ' bg-primary-gray absolute flex w-full flex-row justify-start opacity-0',
                    isMobile
                        ? 'floating-box-wrapper-mobile'
                        : 'floating-box-wrapper'
                )}
            >
                <div
                    className={className(
                        isMobile ? 'floating-box-mobile' : 'floating-box',
                        ' flex flex-col justify-between'
                    )}
                    style={{ backgroundColor, maxWidth: '45rem' }}
                >
                    <SeoableText
                        element='h1'
                        style={{
                            lineHeight: isMobile ? '58.022px' : 'min(11.7vw,110px)',
                        }}
                        className={`font-gravity text-6xl-m leading-hero-sm ${backgroundColor === '#000000'
                            ? 'text-[#e6f0e9]'
                            : 'text-primary-black'
                            } md:text-[12rem]`}
                        dangerouslySetInnerHTML={{
                            __html: title || mobileTitle,
                        }}
                    />
                    <div>
                        <SeoableText
                            element={'h2'}
                            className={`mb-6 mt-[1.3rem] text-lg leading-heroDescMobile ${backgroundColor === '#000000'
                                ? 'text-[#e6f0e9]'
                                : 'text-primary-black'
                                } md:mb-0  md:text-2xl md:leading-[3rem] ${isPromotionForm
                                    ? ' md:mt-0'
                                    : ' md:mt-4 md:mb-16'
                                }`}
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                        {/* {props.action} */}

                        {isPromotionForm && (
                            <div className={styles.mainFormClass}>
                                <div className={styles.group}>
                                    <input
                                        autoComplete='off'
                                        className={styles.input}
                                        type={'text'}
                                        placeholder={' '}
                                        id={'email'}
                                        {...register('email', {
                                            required: true,
                                            pattern: /^\S+@\S+$/i,
                                        })}
                                    />
                                    <label
                                        className={styles.label}
                                        htmlFor='email'
                                    >
                                        Enter email address
                                    </label>
                                    <div className={styles.bar}></div>
                                    <AnimatePresence>
                                        {errors.email && (
                                            <motion.span
                                                variants={{
                                                    hidden: { opacity: 0 },
                                                    enter: { opacity: 1 },
                                                    exit: { opacity: 0 },
                                                }}
                                                initial='hidden'
                                                transition={{ duration: '0.5' }}
                                                animate='enter'
                                                exit='exit'
                                                className={
                                                    'mt-2 block text-bpm-supreme'
                                                }
                                            >
                                                This field is required and a
                                                valid Email
                                            </motion.span>
                                        )}
                                    </AnimatePresence>
                                </div>
                            </div>
                        )}

                        <div>
                            <Button
                                className={
                                    isMobile && ActionTwo ? 'w-[48%]' : ''
                                }
                                dark={
                                    backgroundColor === '#000000'
                                        ? false
                                        : action.dark
                                }
                                ref={buttonRef}
                                mobile={isMobile}
                                animated
                                fullWidth={
                                    action.fullWidth || (isMobile && !ActionTwo)
                                }
                                onClick={() => (isPromotionForm
                                    ? handleSubmit(onSubmit)()
                                    : router.push(
                                        action.page.data.attributes.slug
                                    ))
                                }
                            >
                                {action.text}
                            </Button>
                            {ActionTwo && (
                                <Button
                                    className={`ml-4 ${isMobile && ActionTwo ? 'w-[48%]' : ''
                                        }`}
                                    transparent={ActionTwo.transparent}
                                    dark={
                                        backgroundColor === '#000000'
                                            ? true
                                            : ActionTwo.dark
                                    }
                                    fullWidth={ActionTwo.fullWidth}
                                    hasArrow
                                    mobile={isMobile}
                                    onClick={() => router.push(
                                        ActionTwo.page.data.attributes.slug
                                    )
                                    }
                                >
                                    {ActionTwo.text}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </motion.div>

            <style jsx>
                {`
                    :global(.floating-box-wrapper-mobile) {
                        min-height: 37.3vh;
                        @apply bottom-[2rem] left-8 right-8 w-auto;
                    }
                    :global(.floating-box-wrapper) {
                        height: 100%;
                        padding-top: 4rem;
                        padding-bottom: 4rem;
                        padding-left: 4rem;
                    }
                    .floating-box {
                        width: 100%;
                        height: 100%;
                        max-width: 29.14%;
                        min-height: 60rem;
                        padding: 4rem;
                        overflow: hidden;
                    }

                    .floating-box-mobile {
                        @apply w-full p-8;
                    }

                    .hero-action :global(li) {
                        @apply mt-2;
                    }
                `}
            </style>
        </header>
    );
});

export { Hero };
