import className from 'classnames';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';

import type { ICarouselHandle } from '@/components/Carousel/Carousel';
import { Carousel } from '@/components/Carousel/Carousel';
import NextImage from '@/components/image';
import { Section } from '@/layouts/Section';
import { useStore } from '@/stores/RootStore';
import { If } from '@/utils/If';

import type { IDataGridSectionOld } from './DataGridSectionOld';

const DataGridSectionMobileOld = observer((props: IDataGridSectionOld) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
    const carouselRef = useRef<ICarouselHandle>(null);
    const {
        layoutStore: { accent },
    } = useStore();
    return (
        <Section
            titleClass={'ml-[-2rem] mb-[-3.4rem] text-[4.8rem] leading-[4.8rem]'}
            padding={'pt-8 md:pt-36 mt-5 ml-8'}
            title={' A Complete Music Suite'}
            classNames={'mt-[10rem] md:mt-[0]'}
        >
            <div className={'wrapper grid '}>
                <If condition={props.data.Tiles.length > 0}>
                    <Carousel
                        initialWidth={95.5}
                        margin={8}
                        ref={carouselRef}
                        onChange={setActiveSlideIndex}
                    >
                        {props.data.Tiles.map((item: any, i: number) => {
                            const {
                                Image, Text, Link = null, Title
                            } = item;
                            const blockClass = className(
                                'flex',
                                'justify-between',
                                'bg-bpm-darkGray',
                                'px-[3.0rem] pt-[3.0rem] pb-[3.0rem]',
                                'flex-col big'
                            );
                            return (
                                <div className={blockClass} key={`BlockItem-${i}`}>
                                    <div className={className('text', Link && 'h-fit')}>
                                        <div>
                                            <h2 className={'span'}>{Title}</h2>
                                            <p>{Text}</p>
                                        </div>
                                    </div>
                                    <div
                                        className={'image relative mt-7 w-full'}
                                        style={{ height: '31.2rem' }}
                                    >
                                        <NextImage
                                            src={''}
                                            priority
                                            media={Image}
                                            alt={Title}
                                            layout="fill"
                                            objectFit="contain"
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </Carousel>
                    <div className={'navigation'}>
                        {props.data.Tiles.map((_, i: number) => (
                            <motion.div
                                onClick={() => carouselRef.current?.slideTo(i)}
                                animate={
                                    activeSlideIndex === i
                                        ? { backgroundColor: accent || '#FF2F00', width: 40 }
                                        : { width: 15 }
                                }
                                transition={{ ease: 'easeInOut', duration: 0.4 }}
                                key={i}
                            />
                        ))}
                    </div>
                </If>
            </div>
            <style jsx>
                {`
          .wrapper {
            margin-bottom: 7.4rem;
          }

          :global(.wrapper h2) {
            @apply text-base;
          }

          :global(.wrapper p) {
            @apply text-base opacity-70 leading-[2.2rem] mt-[0.8rem] max-w-[24.1rem] min-h-[11rem];
          }

          :global(.text) {
            @apply border-l-2 border-white border-opacity-20 pl-[1.7rem];
          }

          :global(.big) {
            grid-row: span 4;
          }

          :global(.big .text) {
            max-width: 39rem;
          }

          :global(.action) {
            @apply text-xl underline cursor-pointer;
          }

          :global(.navigation) {
            @apply flex flex-row justify-center my-12 ml-[-2rem];
          }

          :global(.navigation div) {
            width: 1.5rem;
            height: 0.2rem;
            @apply bg-bpm-white bg-opacity-30 m-2;
          }
        `}
            </style>
        </Section>
    );
});

export { DataGridSectionMobileOld };
