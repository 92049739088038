import React from 'react';

const SeoableText = (props: any) => {
    const { element, ...other } = props;
    let content = props.children || (props.dangerouslySetInnerHTML && props.dangerouslySetInnerHTML.__html);
    const matches = content && content.match(/\[(.*?)\]/g);
    content = matches && matches.length > 0 ? content.replace(`${matches[0]}`, '') : content;
    const CustomTag = `${(matches && matches.length > 0 && matches[0].substr(1, matches[0].length - 2)) || element}` as keyof JSX.IntrinsicElements;
    if (props.children) {
        return <CustomTag aria-seoable {...other} />;
    }
    return <CustomTag aria-seoable {...other} dangerouslySetInnerHTML={{ __html: content }} />;
};

export default SeoableText;
