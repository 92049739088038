import PreviewModeBanner from '@/components/PreviewModeBanner';
import type { GlobalEntity } from '@/generated/graphql-types';
import { CallForAction } from '@/modules/CallForAction/CallForAction';
import { ContentHero } from '@/modules/ContentHero/ContentHero';
import DataGridSectionWrapper from '@/modules/DataGridSection/DataGridWrapper';
import DataGridSectionWrapperOld from '@/modules/DataGridSection/DataGridWrapperOld';
import { FAQ } from '@/modules/faq/FAQ';
import FeaturesScrollSectionWrapper from '@/modules/FeaturesScrollSection/FeaturesScrollSectionWrapper';
import { ForgotPassword } from '@/modules/ForgotPassword/ForgotPassword';
import { FormAndImageView } from '@/modules/FormAndImageView/FormAndImageView';
import { GenresRunner } from '@/modules/GenresRunner/GenresRunner';
import { GetTheApp } from '@/modules/GetTheApp/GetTheApp';
import { GetTheAppWithIcon } from '@/modules/GetTheAppWithIcon/GetTheAppWithIcon';
import GiveawaySection from '@/modules/GiveawaySection/GiveawaySection';
import { Hero } from '@/modules/hero/Hero';
import { ImageWIthParallax } from '@/modules/ImageWIthParallax/ImageWIthParallax';
import LogoBoxesWrapper from '@/modules/LogoBoxes/LogoBoxesWrapper';
import { PartnersForm } from '@/modules/PartnersForm/PartnersForm';
import { PlatformSelectorView } from '@/modules/PlatformSelectorView/PlatformSelectorView';
import { PricingTable } from '@/modules/PricingTable/PricingTable';
import RichTextPage from '@/modules/RichTextPage/RichTextPage';
import SlidingCoversHero from '@/modules/SlidingCoversHero/SlidingCoversHero';
import { SupremeLibrariesBlocks } from '@/modules/SupremeLibrariesBlocks/SupremeLibrariesBlocks';
import { TestimonialSlider } from '@/modules/TestimonialSlider/TestimonialSlider';
import { TextAndImage } from '@/modules/TextAndImage/TextAndImage';
import { TextWIthParallax } from '@/modules/TextWIthParallax/TextWIthParallax';
import { TracksParallaxBox } from '@/modules/TracksParallaxBox/TracksParallaxBox';

// Map Strapi sections to section components
const sectionComponents: any = {
    ComponentSectionsHero: Hero,
    ComponentSectionsCallToAction: CallForAction,
    ComponentSectionsFaq: FAQ,
    ComponentSectionsTestimonialsSlider: TestimonialSlider,
    ComponentSectionsDataTilesGrid: DataGridSectionWrapperOld,
    ComponentSectionsFeaturesScrollSection: FeaturesScrollSectionWrapper,
    ComponentSectionsLibrariesSection: SupremeLibrariesBlocks,
    ComponentSectionsGetTheApp: GetTheApp,
    ComponentSectionsTracksParallaxBox: TracksParallaxBox,
    ComponentSectionsPricing: PricingTable,
    ComponentSectionsRichText: RichTextPage,
    ComponentSectionsFormAndImage: FormAndImageView,
    ComponentSectionsPlatformsSelector: PlatformSelectorView,
    ComponentSectionsImageWithParallax: ImageWIthParallax,
    ComponentSectionsGenresRunner: GenresRunner,
    ComponentSectionsContentHero: ContentHero,
    ComponentSectionsTextAndImage: TextAndImage,
    ComponentSectionsTextAndButtonWithParallax: TextWIthParallax,
    ComponentSectionsGetTheAppWithIcon: GetTheAppWithIcon,
    ComponentSectionsLogoBoxes: LogoBoxesWrapper,
    ComponentSectionsForgotPassword: ForgotPassword,
    ComponentSectionsPartnersForm: PartnersForm,
    ComponentSectionsFeaturesCards: DataGridSectionWrapper,
    ComponentSectionsGiveaway: GiveawaySection,
    ComponentSectionsSlidingCoversHero: SlidingCoversHero,
};

// Display a section individually
const Section = ({ sectionData, global }: { sectionData: any; global: GlobalEntity }) => {
    // Prepare the component
    const SectionComponent: any = sectionComponents[sectionData.__typename];

    if (!SectionComponent) {
        return null;
    }

    // Display the section
    return <SectionComponent global={global} data={sectionData} />;
};

// Display the list of sections
const Sections = ({
    sections,
    preview,
    global,
}: {
    sections: any;
    preview: boolean;
    global: GlobalEntity
}) => (
    <div className="flex flex-col">
        {/* Show a banner if preview mode is on */}
        {preview && <PreviewModeBanner />}
        {/* Show the actual sections */}
        {sections.map((section: any, index: number) => (
            <Section
                global={global}
                sectionData={section}
                key={`${section.__typename}${index}`}
            />
        ))}
    </div>
);

export default Sections;
