import type { ReactElement } from 'react';
import React from 'react';

interface Props {
    condition: boolean | unknown;
    comment?: string;
}

export function If(props: React.PropsWithChildren<Props>): ReactElement | null {
    if (props.condition) {
        return <>{props.children}</>;
    }

    return null;
}
